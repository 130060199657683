import { register } from "src/excalidraw/actions/register";
import { ZoomInIcon } from "src/excalidraw/components/icons";
import { ToolButton } from "src/excalidraw/components/ToolButton";
import {
  CANVAS_HEADER_HEIGHT,
  DEFAULT_JOB_HEIGHT,
  JOB_ELEMENTS_WIDTH,
  COMPRESSED_JOB_HEIGHT,
  PRIORITY,
  GRID_SIZE,
} from "src/excalidraw/constants";
import { mutateElement, newElementWith } from "src/excalidraw/element/mutateElement";
import { getBoundTextElement, handleBindTextResize } from "src/excalidraw/element/textElement";
import { ElementsMap, ExcalidrawElement, NonDeletedExcalidrawElement } from "src/excalidraw/element/types";
import { t } from "src/excalidraw/i18n";
import { getSelectedElements } from "src/excalidraw/scene";
import { AppState } from "src/excalidraw/types";
import Calendar from "../calendar";
import ColorsEx from "../constants/ColorsEx";
import { updateBoundElementsEx } from "../element/binding";
import { newJobElement } from "../element/newElement";
import { isBindableElementEx, isJobElement, isJobTextElement, isLinkElement, isNodeElement } from "../element/typeChecks";
import { ExcalidrawJobElement, ExcalidrawLinkElement, ExcalidrawNodeElement } from "../element/types";
import Job from "../job";
import _ from "lodash";
import { getNonDeletedElements, isTextElement, redrawTextBoundingBox } from "src/excalidraw/element";
import { arrayToMap } from "src/excalidraw/utils";

type JobRowTypes = "compressRow" | "expandRow";

const newJobRow = (
  elements: readonly ExcalidrawElement[],
  elementsMap: ElementsMap,
  appState: AppState,
  newY: number,
): ExcalidrawJobElement => {
  const jobElements = Job.getJobElements(elements);
  const newElement = newJobElement({
    x: 0,
    y: newY,
    strokeColor: ColorsEx.lineColor.border, // CHANGED:UPDATE 2023-03-01 #726
    backgroundColor: ColorsEx.backgroundColor.white,
    width: JOB_ELEMENTS_WIDTH,
    height: DEFAULT_JOB_HEIGHT,
    priority: PRIORITY.job, // CHANGED:ADD 2023-01-23 #391
  });

  elements
    .filter((el) => !el.isDeleted && !isJobElement(el) && !isJobTextElement(el))
    .forEach((el) => {
      if (newElement.y < el.y) {
        mutateElement(el, {
          y: el.y + newElement.height,
        });
      }
    });

  jobElements
    .filter((element) => element.y >= newElement.y)
    .forEach((element) => {
      mutateElement(element, {
        y: element.y + newElement.height,
      });

      const boundText = getBoundTextElement(element, elementsMap);
      if (boundText) {
        handleBindTextResize(element, elementsMap, false);
      }
    });

  const calendar = new Calendar(
    appState.gridSize,
    appState.projectStartDate,
    appState.holidays,
  );

  // update nodeElement's bound link
  (elements
    .filter((element) => {
      if (isLinkElement(element)) {
        return isLinkElement(element) && element.y + element.height > newElement.y;
      }
      return false;
    }) as ExcalidrawLinkElement[])
    .forEach((linkElement) => {
      if (linkElement.startBinding) {
        const startBindingElement = elementsMap.get(linkElement.startBinding.elementId);
        if (startBindingElement && isBindableElementEx(startBindingElement)) {
          updateBoundElementsEx(startBindingElement, elementsMap, appState, calendar);
        }
      }

      if (linkElement.endBinding) {
        const endBindingElement = elementsMap.get(linkElement.endBinding.elementId);
        if (endBindingElement && isBindableElementEx(endBindingElement)) {
          updateBoundElementsEx(endBindingElement, elementsMap, appState, calendar);
        }
      }
    });

  return newElement;
};

export const actionAddJobRow = register({
  name: "addJobRow",
  trackEvent: { category: "canvas" },
  perform: (elements, appState, _, app) => {
    const jobElements = Job.getJobElements(elements);
    const newElement = newJobRow(
      elements,
      app.scene.getNonDeletedElementsMap(),
      appState,
      Job.getJobElementsHeight(jobElements) + CANVAS_HEADER_HEIGHT,
    );
    const nextJobElemnts: ExcalidrawJobElement[] = Job.getJobElements([
      ...elements,
      newElement,
    ]);
    const nextElements: ExcalidrawElement[] = [...elements, newElement];
    const jobsHeight = Job.getJobElementsHeight(nextJobElemnts);
    return {
      elements: nextElements,
      appState: {
        ...appState,
        editingElement: newElement,
        jobsHeight,
        selectedElementIds: {
          [newElement.id]: true,
        },
      },
      commitToHistory: true,
      updatedJobElements: true, // CHANGED:ADD 2023-2-10 #638
      addingNewJobElement: true,
    };
  },
  PanelComponent: ({ updateData }) => (
    <ToolButton
      type="button"
      icon={ZoomInIcon}
      title={`${t("buttons.addJobRow")}`}
      aria-label={t("buttons.addJobRow")}
      onClick={() => {
        updateData(null);
      }}
    />
  ),
  keyTest: (event) => false,
});

export const actionInsertJobRowAbove = register({
  name: "insertJobRowAbove",
  trackEvent: { category: "canvas" },
  predicate: (elements, appState) => {
    const selectedElements = getSelectedElements(elements, appState);
    return selectedElements.length === 1 && isJobElement(selectedElements[0]);
  },
  perform: (elements, appState, _, app) => {
    const selectedElements = getSelectedElements(elements, appState);

    const newElement = newJobRow(
      elements,
      app.scene.getNonDeletedElementsMap(),
      appState,
      selectedElements[0].y,
    );
    const nextJobElemnts: ExcalidrawJobElement[] =
      Job.getJobElements([...elements, newElement]);
    const nextElements: ExcalidrawElement[] = [...elements, newElement];
    const jobsHeight = Job.getJobElementsHeight(nextJobElemnts);

    return {
      elements: nextElements,
      appState: {
        ...appState,
        editingElement: newElement,
        jobsHeight,
        selectedElementIds: {
          [newElement.id]: true,
        },
      },
      commitToHistory: true,
      updatedJobElements: true, // CHANGED:ADD 2023-2-10 #638
      addingNewJobElement: true,
    };
  },
  contextItemLabel: "labels.jobRow.insertAdobe",
});

export const actionInsertJobRowBelow = register({
  name: "insertJobRowBelow",
  trackEvent: { category: "canvas" },
  predicate: (elements, appState) => {
    const selectedElements = getSelectedElements(elements, appState);
    return selectedElements.length === 1 && isJobElement(selectedElements[0]);
  },
  perform: (elements, appState, _, app) => {
    const selectedElements = getSelectedElements(elements, appState);

    const newElement = newJobRow(
      elements,
      app.scene.getNonDeletedElementsMap(),
      appState,
      selectedElements[0].y + selectedElements[0].height,
    );
    const nextJobElemnts: ExcalidrawJobElement[] =
      Job.getJobElements([...elements, newElement]);
    const nextElements: ExcalidrawElement[] = [...elements, newElement];
    const jobsHeight = Job.getJobElementsHeight(nextJobElemnts);

    return {
      elements: nextElements,
      appState: {
        ...appState,
        editingElement: newElement,
        jobsHeight,
        selectedElementIds: {
          [newElement.id]: true,
        },
      },
      commitToHistory: true,
      updatedJobElements: true, // CHANGED:ADD 2023-2-10 #638
      addingNewJobElement: true,
    };
  },
  contextItemLabel: "labels.jobRow.insertBelow",
});

export const actionExpandCollapseAllJobRow = register({
  name: "expandCollapseAllJobRow",
  trackEvent: { category: "canvas" },
  perform: (elements, appState, isCompression: boolean, app) => {
    const nonDeletedElements = getNonDeletedElements(elements);
    const allJobElements = nonDeletedElements.filter((el) => isJobElement(el)) as ExcalidrawJobElement[];

    const updatedElements = expandCollapseJobElements(
      nonDeletedElements,
      app.scene.getNonDeletedElementsMap(),
      appState,
      allJobElements,
      isCompression,
    );
    const updatedJobElements = Job.getJobElements(updatedElements);
    const jobsHeight = Job.getJobElementsHeight(updatedJobElements);

    return {
      elements: updatedElements,
      appState: {
        ...appState,
        jobsHeight,
      },
      commitToHistory: true,
      updatedJobElements: true,
    }
  },
  PanelComponent: ({ elements, appState, updateData }) => {
    const nonDeletedElements = getNonDeletedElements(elements);
    const allJobElements = nonDeletedElements.filter((el) => isJobElement(el)) as ExcalidrawJobElement[];

    const enableExpand = allJobElements.some((el) => el.isCompressed);
    const enableCollapse = allJobElements.some((el) => !el.isCompressed);

    return (
      <div className="export_rm">
        <label className="w-full themeSwitcherTwo shadow-card relative inline-flex cursor-pointer select-none items-center justify-center border-ra rounded-[5px] border-solid border-[1px] border-[#DDDDDD] bg-white mt-4">
          <fieldset
            disabled={!enableExpand}
            className="w-1/2 hover:opacity-75 disabled:pointer-events-none"
          >
            <div
              className={`fixed__switch rounded-[5px] rounded-r-none ${
                enableExpand ? "bg-[#F5F6F7]" : "text-[#DDDDDD]"
              }`}
              onClick={() => updateData(false)}
            >
              {t("buttons.expandAllJobRow")}
            </div>
          </fieldset>
          <fieldset
            disabled={!enableCollapse}
            className="w-1/2 hover:opacity-75 disabled:pointer-events-none border-[#DDDDDD] border-l border-solid"
          >
            <div
              className={`fixed__switch rounded-[5px] rounded-l-none ${
                enableCollapse ? "bg-[#F5F6F7]" : "text-[#DDDDDD]"
              }`}
              onClick={() => updateData(true)}
            >
              {t("buttons.collapseAllJobRow")}
            </div>
          </fieldset>
        </label>
      </div>
    );
  },
  keyTest: (event) => false,
});

const expandCollapseSelectedJobElements = (
  elements: readonly ExcalidrawElement[],
  elementsMap: ElementsMap,
  appState: Readonly<AppState>,
) => {
  const nonDeletedElements = getNonDeletedElements(elements);
  const selectedJobElements = getSelectedElements(nonDeletedElements, appState, {
    includeBoundTextElement: false,
  }).filter((el) => isJobElement(el)) as ExcalidrawJobElement[];

  const isCompression = getOperation(selectedJobElements) === "compressRow";

  return expandCollapseJobElements(
    elements,
    elementsMap,
    appState,
    selectedJobElements,
    isCompression,
  );
};

const expandCollapseJobElements = (
  elements: readonly NonDeletedExcalidrawElement[],
  elementsMap: ElementsMap,
  appState: Readonly<AppState>,
  jobElements: ExcalidrawJobElement[],
  isCompression: boolean,
) => {
  const gridSize = appState.gridSize ? appState.gridSize : GRID_SIZE;

  const calendar = new Calendar(
    appState.gridSize,
    appState.projectStartDate,
    appState.holidays,
  );

  const updatedElements: NonDeletedExcalidrawElement[] = [];

  elements.forEach((el, _, array) => {
    Job.setJobId(el, array);
  });

  jobElements
    .sort((a, b) => a.y - b.y)
    .forEach((element) => {
      const boundText = getBoundTextElement(element, elementsMap);
      const prevH = element.height;
      const originalHeight = element.isCompressed ? element.originalHeight : element.height;
      let newH = isCompression ? COMPRESSED_JOB_HEIGHT : originalHeight;

      // change selected job element's height
      const newElement = mutateElement(element, {
        height: newH,
        originalHeight,
        isCompressed: isCompression,
      });

      if (boundText && isTextElement(boundText)) {
        const newTextElement =
          newElementWith(boundText, {
            text: isCompression
              ? boundText.originalText.split('\n')[0]
              : boundText.originalText,
            isCompressed: isCompression,
          });

        if (isCompression) {
          redrawTextBoundingBox(newTextElement, element, elementsMap);

          if (newH < element.height) {
            newH = Job.getApproximateJobHeightByTextHeight(element.height, gridSize);

            mutateElement(element, {
              height: newH,
            });
          }
        }

        redrawTextBoundingBox(newTextElement, element, elementsMap);
        updatedElements.push(newTextElement);
      }

      const diffH = newH - prevH;

      Job.updateJobElements(
        elements,
        elementsMap,
        element.y,
        prevH,
        diffH,
        appState,
        calendar,
      );

      (
        elements.filter((element) =>
          isNodeElement(element),
        ) as ExcalidrawNodeElement[]
      ).forEach((el) => {
        if (el.jobId === newElement.id) {
          mutateElement(el, {
            y: isCompression ? newElement.y + newElement.height : element.y + el.jobOffsetY,
            isVisible: !isCompression,
          });

          const boundText = getBoundTextElement(el, elementsMap);
          if (boundText && isTextElement(boundText)) {
            mutateElement(boundText, {
              isVisible: !isCompression,
            });
          }
        }
      });

      (
        elements.filter((element) =>
          isLinkElement(element),
        ) as ExcalidrawLinkElement[]
      ).forEach((el) => {
        const startBindingElement = elements?.find(
          (boundElement) => boundElement.id === el.startBinding?.elementId);
        const endBindingElement = elements?.find(
          (boundElement) => boundElement.id === el.endBinding?.elementId);

        const isVisible = startBindingElement?.isVisible && endBindingElement?.isVisible;
        if (el.isVisible !== isVisible) {
          mutateElement(el, {
            isVisible,
          });

          const boundText = getBoundTextElement(el, elementsMap);
          if (boundText && isTextElement(boundText)) {
            mutateElement(boundText, {
              isVisible,
            });
          }

          if (isVisible) {
            if (startBindingElement) {
              updateBoundElementsEx(
                startBindingElement,
                elementsMap,
                appState,
                calendar,
              );
            }

            if (endBindingElement) {
              updateBoundElementsEx(
                endBindingElement,
                elementsMap,
                appState,
                calendar
              );
            }
          }
        }
      });
    });

  const updatedElementsMap = arrayToMap(updatedElements);

  return elements.map(
    (element) => updatedElementsMap.get(element.id) || element,
  );
};

export const actionToggleJobRowExpansion = register({
  name: "toggleJobCompression",
  trackEvent: { category: "canvas" },
  predicate: (elements, appState) => {
    const selectedElements = getSelectedElements(elements, appState);
    return !selectedElements.some((element) => !isJobElement(element));
  },
  perform: (elements, appState, _, app) => {
    const updatedElements = expandCollapseSelectedJobElements(
      elements,
      app.scene.getNonDeletedElementsMap(),
      appState
    );
    const updatedJobElements = Job.getJobElements(updatedElements);
    const jobsHeight = Job.getJobElementsHeight(updatedJobElements);

    return {
      elements: updatedElements,
      appState: {
        ...appState,
        jobsHeight,
      },
      commitToHistory: true,
      updatedJobElements: true,
    }
  },
  contextItemLabel(elements, appState, app) {
    const selected = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: false,
    }).filter((el) => isJobElement(el)) as ExcalidrawJobElement[];
    if (selected.length === 1) {
      return selected[0].isCompressed
        ? "labels.jobRow.expandRow"
        : "labels.jobRow.compressRow";
    }

    return getOperation(selected) === "compressRow"
      ? "labels.jobRow.compressRow"
      : "labels.jobRow.expandRow";
  },
});

const getOperation = (
  elements: readonly ExcalidrawElement[],
): JobRowTypes => (elements.some((el) => isJobElement(el) && !el.isCompressed) ? "compressRow" : "expandRow");
