export default {
  lineColor: {
    white: "#FFFFFF",
    black: "#000000",
    today: "red",
    font: "#222222",
    daysOfWeekFont: "#999999",
    todayFont: "#FFFFFF",
    todayDaysOfWeekFont: "#A8D7F0",
    border: "#DDDDDD",
    criticalPath: "#46AADF",
    separator: "#00000040",
    highlighted: "#529BF5",
    milestone: "#FAB005",
    shadow: "#0E00C0",
  },

  backgroundColor: {
    white: "#FFFFFF",
    today: "#46AADF",
    month: "#F5F6F7",
    day: "#F5F6F7",
    holiday: "#A9B2C6",
    workingDay: "#FDFDFD",
    milestone: "#02BB9D",
    calendar: "#FFFFFF00",
  },
};
