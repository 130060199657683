import React from "react";
import {
  PointerType,
  ExcalidrawLinearElement,
  NonDeletedExcalidrawElement,
  NonDeleted,
  TextAlign,
  ExcalidrawElement,
  GroupId,
  ExcalidrawBindableElement,
  Arrowhead,
  ChartType,
  FontFamilyValues,
  FileId,
  ExcalidrawImageElement,
  Theme,
  StrokeRoundness,
  TextDirection,
  PointerDirection,
  VerticalAlign,
} from "./element/types";
// CHANGED:ADD 2022-10-26 #14
import { ArrowheadEx, ExcalidrawBindableElementEx } from "./extensions/element/types"; // from extensions
import { SHAPES } from "./shapes";
import { Point as RoughPoint } from "roughjs/bin/geometry";
import { LinearElementEditor } from "./element/linearElementEditor";
// CHANGED:ADD 2022-10-26 #14
import { TaskElementEditor } from "./extensions/element/taskElementEditor"; // from extensions
// CHANGED:ADD 2022-11-2 #64
import { LinkElementEditor } from "./extensions/element/linkElementEditor"; // from extensions
import { SuggestedBinding } from "./element/binding";
// CHANGED:ADD 2022-10-26 #14
import { SuggestedBindingEx } from "./extensions/element/binding"; // from extensions
import { ImportedDataState } from "./data/types";
import type App from "./components/App";
import type { ResolvablePromise, throttleRAF } from "./utils";
import { Spreadsheet } from "./charts";
import { Language } from "./i18n";
import { ClipboardData } from "./clipboard";
import { isOverScrollBars } from "./scene";
import { MaybeTransformHandleType } from "./element/transformHandles";
import Library from "./data/library";
import type { FileSystemHandle } from "./data/filesystem";
import type { IMAGE_MIME_TYPES, MIME_TYPES } from "./constants";
import { ContextMenuItems } from "./components/ContextMenu";
import { Merge, ForwardRef, ValueOf } from "./utility-types";
import { ProjectRole } from "src/conpath/constants/Role";

export type Point = Readonly<RoughPoint>;

export type SocketId = string & { _brand: "SocketId" };

export type Collaborator = Readonly<{
  pointer?: CollaboratorPointer;
  button?: "up" | "down";
  selectedElementIds?: AppState["selectedElementIds"];
  username?: string | null;
  userState?: UserIdleState;
  color?: {
    background: string;
    stroke: string;
  };
  // The url of the collaborator's avatar, defaults to username initials
  // if not present
  avatarUrl?: string;
  // user id. If supplied, we'll filter out duplicates when rendering user avatars.
  id?: string;
  socketId?: SocketId;
  isCurrentUser?: boolean;
}>;

export type CollaboratorPointer = {
  x: number;
  y: number;
  tool: "pointer" | "laser";
};

export type DataURL = string & { _brand: "DataURL" };

export type BinaryFileData = {
  mimeType:
  | ValueOf<typeof IMAGE_MIME_TYPES>
  // future user or unknown file type
  | typeof MIME_TYPES.binary;
  id: FileId;
  dataURL: DataURL;
  /**
   * Epoch timestamp in milliseconds
   */
  created: number;
  /**
   * Indicates when the file was last retrieved from storage to be loaded
   * onto the scene. We use this flag to determine whether to delete unused
   * files from storage.
   *
   * Epoch timestamp in milliseconds.
   */
  lastRetrieved?: number;
};

export type BinaryFileMetadata = Omit<BinaryFileData, "dataURL">;

export type BinaryFiles = Record<ExcalidrawElement["id"], BinaryFileData>;

export type ToolType =
  | "selection"
  | "rectangle"
  | "diamond"
  | "ellipse"
  | "arrow"
  | "line"
  | "freedraw"
  | "text"
  | "image"
  | "eraser"
  | "hand"
  | "task"
  | "milestone"
  | "link"
  | "job"
  | "job-text"
  | "comment"
  | "laser";

export type ActiveTool =
  | {
    type: ToolType;
    customType: null;
  }
  | {
    type: "custom";
    customType: string;
  };

type _CommonCanvasAppState = {
  zoom: AppState["zoom"];
  scrollX: AppState["scrollX"];
  scrollY: AppState["scrollY"];
  todayScrollX: AppState["todayScrollX"];
  width: AppState["width"];
  height: AppState["height"];
  viewModeEnabled: AppState["viewModeEnabled"];
  editingGroupId: AppState["editingGroupId"]; // TODO: move to interactive canvas if possible
  selectedElementIds: AppState["selectedElementIds"]; // TODO: move to interactive canvas if possible
  offsetLeft: AppState["offsetLeft"];
  offsetTop: AppState["offsetTop"];
  theme: AppState["theme"];
  pendingImageElementId: AppState["pendingImageElementId"];
};

export type StaticCanvasAppState = Readonly<
  _CommonCanvasAppState & {
    shouldCacheIgnoreZoom: AppState["shouldCacheIgnoreZoom"];
    /** null indicates transparent bg */
    viewBackgroundColor: AppState["viewBackgroundColor"] | null;
    exportScale: AppState["exportScale"];
    gridSize: AppState["gridSize"];
    emphasizedModeEnabled: AppState["emphasizedModeEnabled"];
    transparentModeEnabled: AppState["transparentModeEnabled"];
    criticalPathModeEnabled: AppState["criticalPathModeEnabled"];
    selectedTaskElement: AppState["selectedTaskElement"];
    selectedLinkElement: AppState["selectedLinkElement"];
    holidays: AppState["holidays"];
  }
>;

export type InteractiveCanvasAppState = Readonly<
  _CommonCanvasAppState & {
    // renderInteractiveScene
    editingLinearElement: AppState["editingLinearElement"];
    selectionElement: AppState["selectionElement"];
    selectedGroupIds: AppState["selectedGroupIds"];
    selectedLinearElement: AppState["selectedLinearElement"];
    selectedTaskElement: AppState["selectedTaskElement"];
    selectedLinkElement: AppState["selectedLinkElement"];
    multiElement: AppState["multiElement"];
    isBindingEnabled: AppState["isBindingEnabled"];
    isBindingEnabledEx: AppState["isBindingEnabledEx"];
    suggestedBindings: AppState["suggestedBindings"];
    suggestedBindingsEx: AppState["suggestedBindingsEx"];
    isRotating: AppState["isRotating"];
    // App
    openSidebar: AppState["openSidebar"];
    showHyperlinkPopup: AppState["showHyperlinkPopup"];
    // Collaborators
    collaborators: AppState["collaborators"];
  }
>;

export interface AppState {
  contextMenu: {
    items: ContextMenuItems;
    top: number;
    left: number;
  } | null;
  showWelcomeScreen: boolean;
  isLoading: boolean;
  errorMessage: React.ReactNode;
  draggingElement: NonDeletedExcalidrawElement | null;
  resizingElement: NonDeletedExcalidrawElement | null;
  multiElement: NonDeleted<ExcalidrawLinearElement> | null;
  selectionElement: NonDeletedExcalidrawElement | null;
  isBindingEnabled: boolean;
  isBindingEnabledEx: boolean; // CHANGED:ADD 2022-10-26 #14
  startBoundElement: NonDeleted<ExcalidrawBindableElement> | null;
  startBoundElementEx: NonDeleted<ExcalidrawBindableElementEx> | null; // CHANGED:ADD 2022-10-26 #14
  suggestedBindings: SuggestedBinding[];
  suggestedBindingsEx: SuggestedBindingEx[]; // CHANGED:ADD 2022-10-26 #14
  // element being edited, but not necessarily added to elements array yet
  // (e.g. text element when typing into the input)
  editingElement: NonDeletedExcalidrawElement | null;
  editingLinearElement: LinearElementEditor | null;
  activeTool: {
    /**
     * indicates a previous tool we should revert back to if we deselect the
     * currently active tool. At the moment applies to `eraser` and `hand` tool.
     */
    lastActiveTool: ActiveTool | null;
    locked: boolean;
  } & ActiveTool;
  penMode: boolean;
  penDetected: boolean;
  exportBackground: boolean;
  exportEmbedScene: boolean;
  exportWithDarkMode: boolean;
  exportScale: number;
  currentItemStrokeColor: string;
  currentItemBackgroundColor: string;
  currentItemFillStyle: ExcalidrawElement["fillStyle"];
  currentItemStrokeWidth: number;
  currentItemStrokeWidthTask: number; // CHANGED:ADD 2023/2/9 #601
  currentItemStrokeWidthLink: number; // CHANGED:ADD 2023/2/9 #601
  currentItemStrokeStyle: ExcalidrawElement["strokeStyle"];
  currentItemRoughness: number;
  currentItemOpacity: number;
  currentItemFontFamily: FontFamilyValues;
  currentItemFontSize: number;
  currentItemTextAlign: TextAlign;
  currentItemStartArrowhead: Arrowhead | null;
  currentItemStartArrowheadEx: ArrowheadEx | null; // CHANGED:ADD 2024-04-15 #1931
  currentItemEndArrowhead: Arrowhead | null;
  currentItemEndArrowheadEx: ArrowheadEx | null; // CHANGED:ADD 2024-03-24 #1845
  currentItemRoundness: StrokeRoundness;
  currentItemTextHorizontalAlign: TextAlign, // CHANGED:ADD 2024-03-27 #1881
  currentItemTextVerticalAlign: VerticalAlign, // CHANGED:ADD 2024-03-27 #1881
  currentItemTextBorderNone?: boolean, // CHANGED:ADD 2024-03-27 #1790
  currentItemTextBorderOpacity?: number, // CHANGED:ADD 2024-03-27 #1779
  currentItemTextDirection: TextDirection; // CHANGED:ADD 2024/02/01 #1510
  currentItemPointerDirection: PointerDirection, // CHANGED:ADD 2024-03-11 #1749
  viewBackgroundColor: string;
  scrollX: number;
  scrollY: number;
  todayScrollX: number;
  cursorButton: "up" | "down";
  scrolledOutside: boolean;
  name: string;
  isResizing: boolean;
  isRotating: boolean;
  zoom: Zoom;
  openMenu: "canvas" | "shape" | null;
  openPopup: "canvasColorPicker" | "backgroundColorPicker" | "strokeColorPicker" | null;
  openSidebar: "library" | "threadList" | "comments" | "customSidebar" | null; // CHANGED: ADD 2024-01-22 #1138-3
  openDialog:
  | "imageExport"
  | "printExport"  // CHANGED:ADD 2024-01-17 #1495
  | "help"
  | "jsonExport"
  | "editTask"
  | "settings"
  | "datePicker"
  | "shareLink"
  | "printImage"
  | "addLibrary" // CHANGED:ADD 2024-02-06 #1579
  | null; // CHANGED:UPDATE 2023-2-24 #741
  isSidebarDocked: boolean;

  lastPointerDownWith: PointerType;
  selectedElementIds: Readonly<{ [id: string]: true }>;
  previousSelectedElementIds: { [id: string]: true };
  shouldCacheIgnoreZoom: boolean;
  toast: { message: string; closable?: boolean; duration?: number } | null;
  zenModeEnabled: boolean;
  emphasizedModeEnabled: boolean; // CHANGED:ADD 2023-3-10 #763
  transparentModeEnabled: boolean; // CHANGED:ADD 2023-3-10 #763
  criticalPathModeEnabled: boolean; // CHANGED:ADD 2024-3-9 #1753
  theme: Theme;
  gridSize: number | null;
  viewModeEnabled: boolean;

  /** top-most selected groups (i.e. does not include nested groups) */
  selectedGroupIds: { [groupId: string]: boolean };
  /** group being edited when you drill down to its constituent element
    (e.g. when you double-click on a group's element) */
  editingGroupId: GroupId | null;
  width: number;
  height: number;
  offsetTop: number;
  offsetLeft: number;

  fileHandle: FileSystemHandle | null;
  collaborators: Map<SocketId, Collaborator>;
  showStats: boolean;
  currentChartType: ChartType;
  pasteDialog:
    | {
        shown: false;
        data: null;
      }
    | {
        shown: true;
        data: Spreadsheet;
      };
  /** imageElement waiting to be placed on canvas */
  pendingImageElementId: ExcalidrawImageElement["id"] | null;
  showHyperlinkPopup: false | "info" | "editor";
  selectedLinearElement: LinearElementEditor | null;
  selectedTaskElement: TaskElementEditor | null; // CHANGED:ADD 2022-10-26 #14
  selectedLinkElement: LinkElementEditor | null; // CHANGED:ADD 2022-11-2 #64
  organizationId: string; // CHANGED:ADD 2024-02-06 #1579
  projectId: string; // CHANGED:ADD 2024-02-06 #1579
  projectName: string; // CHANGED:ADD 2022-11-21 #181
  projectStartDate: Date; // CHANGED:ADD 2022-11-21 #181
  projectEndDate: Date; // CHANGED:ADD 2022-11-21 #181
  projectRole: ProjectRole; // CHANGED:ADD 2023-08-10 #904
  projectMembers: string[]; // CHANGED:ADD 2024-02-26 #1707
  jobsHeight: number; // CHANGED:ADD 2022-12-18 #347
  calendarWidth: number; // CHANGED:ADD 2023-1-13 #416
  holidays: string[]; // CHANGED:ADD 2023-1-6 #381
  cloudFileHandle: { // CHANGED:ADD 2023-2-12 #512
    id: string;
    key: string;
  } | null;
  criticalPathColor: string; // CHANGED:ADD 2023-2-22 #1653
};

export type UIAppState = Omit<
  AppState,
  | "suggestedBindings"
  | "startBoundElement"
  | "cursorButton"
  | "scrollX"
  | "scrollY"
>;

export type NormalizedZoomValue = number & { _brand: "normalizedZoom" };

export type Zoom = Readonly<{
  value: NormalizedZoomValue;
}>;

export type PointerCoords = Readonly<{
  x: number;
  y: number;
}>;

export type Gesture = {
  pointers: Map<number, PointerCoords>;
  lastCenter: { x: number; y: number } | null;
  initialDistance: number | null;
  initialScale: number | null;
};

export declare class GestureEvent extends UIEvent {
  readonly rotation: number;
  readonly scale: number;
}

// libraries
// -----------------------------------------------------------------------------
/** @deprecated legacy: do not use outside of migration paths */
export type LibraryItem_v1 = readonly NonDeleted<ExcalidrawElement>[];
/** @deprecated legacy: do not use outside of migration paths */
type LibraryItems_v1 = readonly LibraryItem_v1[];

/** v2 library item */
export type LibraryItem = {
  id: string;
  status: "published" | "unpublished";
  elements: readonly NonDeleted<ExcalidrawElement>[];
  category?: string; // CHANGED:ADD 2024-02-06 #1579
  isBookmarked?: boolean;// CHANGED:ADD 2024-02-11 #1579
  /** timestamp in epoch (ms) */
  created: number;
  createdBy?: string; // CHANGED:ADD 2024-02-06 #1579
  name?: string;
  error?: string;
};
export type LibraryItems = readonly LibraryItem[];
export type LibraryItems_anyVersion = LibraryItems | LibraryItems_v1;

export type LibraryItemsSource =
  | ((
      currentLibraryItems: LibraryItems,
    ) =>
      | Blob
      | LibraryItems_anyVersion
      | Promise<LibraryItems_anyVersion | Blob>)
  | Blob
  | LibraryItems_anyVersion
  | Promise<LibraryItems_anyVersion | Blob>;
// -----------------------------------------------------------------------------

// NOTE ready/readyPromise props are optional for host apps' sake (our own
// implem guarantees existence)
export type ExcalidrawAPIRefValue =
  | ExcalidrawImperativeAPI
  | {
      readyPromise?: ResolvablePromise<ExcalidrawImperativeAPI>;
      ready?: false;
    };

export type ExcalidrawInitialDataState = Merge<
  ImportedDataState,
  {
    libraryItems?:
      | Required<ImportedDataState>["libraryItems"]
      | Promise<Required<ImportedDataState>["libraryItems"]>;
  }
>;

export interface ExcalidrawProps {
  onChange?: (
    elements: readonly ExcalidrawElement[],
    appState: AppState,
    files: BinaryFiles,
  ) => void;
  initialData?:
    | ExcalidrawInitialDataState
    | null
    | Promise<ExcalidrawInitialDataState | null>;
  excalidrawRef?: ForwardRef<ExcalidrawAPIRefValue>;
  isCollaborating?: boolean;
  onPointerUpdate?: (payload: {
    pointer: { x: number; y: number; tool: "pointer" | "laser" };
    button: "down" | "up";
    pointersMap: Gesture["pointers"];
  }) => void;
  onPaste?: (
    data: ClipboardData,
    event: ClipboardEvent | null,
  ) => Promise<boolean> | boolean;
  renderTopRightUI?: (
    isMobile: boolean,
    appState: AppState,
  ) => JSX.Element | null;
  langCode?: Language["code"];
  viewModeEnabled?: boolean;
  zenModeEnabled?: boolean;
  gridModeEnabled?: boolean;
  libraryReturnUrl?: string;
  theme?: Theme;
  name?: string;
  renderCustomStats?: (
    elements: readonly NonDeletedExcalidrawElement[],
    appState: AppState,
  ) => JSX.Element;
  UIOptions?: Partial<UIOptions>;
  detectScroll?: boolean;
  handleKeyboardGlobally?: boolean;
  onLibraryChange?: (libraryItems: LibraryItems) => void | Promise<any>;
  autoFocus?: boolean;
  generateIdForFile?: (file: File) => string | Promise<string>;
  onLinkOpen?: (
    element: NonDeletedExcalidrawElement,
    event: CustomEvent<{
      nativeEvent: MouseEvent | React.PointerEvent<HTMLCanvasElement>;
    }>,
  ) => void;
  onPointerDown?: (
    activeTool: AppState["activeTool"],
    pointerDownState: PointerDownState,
  ) => void;
  onScrollChange?: (scrollX: number, scrollY: number) => void;
  /**
   * Render function that renders custom <Sidebar /> component.
   */
  renderSidebar?: () => JSX.Element | null;
  children?: React.ReactNode;
  initialAppState?: Partial<AppState>;
}

export type SceneData = {
  elements?: ImportedDataState["elements"];
  appState?: ImportedDataState["appState"];
  collaborators?: Map<SocketId, Collaborator>;
  commitToHistory?: boolean;
};

export enum UserIdleState {
  ACTIVE = "active",
  AWAY = "away",
  IDLE = "idle",
}

export type ExportOpts = {
  saveFileToDisk?: boolean;
  onExportToBackend?: (
    exportedElements: readonly NonDeletedExcalidrawElement[],
    appState: AppState,
    files: BinaryFiles,
    canvas: HTMLCanvasElement,
  ) => void;
  renderCustomUI?: (
    exportedElements: readonly NonDeletedExcalidrawElement[],
    appState: AppState,
    files: BinaryFiles,
    canvas: HTMLCanvasElement,
  ) => JSX.Element;
};

// NOTE at the moment, if action name coressponds to canvasAction prop, its
// truthiness value will determine whether the action is rendered or not
// (see manager renderAction). We also override canvasAction values in
// excalidraw package index.tsx.
type CanvasActions = Partial<{
  changeViewBackgroundColor: boolean;
  clearCanvas: boolean;
  export: false | ExportOpts;
  loadScene: boolean;
  saveToActiveFile: boolean;
  saveToActiveCloudFile: boolean; // CHANGED:ADD 2023-2-12 #512
  toggleTheme: boolean | null;
  saveAsImage: boolean;
  printImage: boolean;
}>;

type UIOptions = Partial<{
  dockedSidebarBreakpoint: number;
  canvasActions: CanvasActions;
  /** @deprecated does nothing. Will be removed in 0.15 */
  welcomeScreen?: boolean;
}>;

export type AppProps = Merge<
  ExcalidrawProps,
  {
    UIOptions: Merge<
      UIOptions,
      {
        canvasActions: Required<CanvasActions> & { export: ExportOpts };
      }
    >;
    detectScroll: boolean;
    handleKeyboardGlobally: boolean;
    isCollaborating: boolean;
    children?: React.ReactNode;
  }
>;

/** A subset of App class properties that we need to use elsewhere
 * in the app, eg Manager. Factored out into a separate type to keep DRY. */
export type AppClassProperties = {
  props: AppProps;
  interactiveCanvas: HTMLCanvasElement | null;
  /** static canvas */
  canvas: HTMLCanvasElement;
  focusContainer(): void;
  library: Library;
  imageCache: Map<
    FileId,
    {
      image: HTMLImageElement | Promise<HTMLImageElement>;
      mimeType: ValueOf<typeof IMAGE_MIME_TYPES>;
    }
  >;
  files: BinaryFiles;
  device: App["device"];
  scene: App["scene"];
  pasteFromClipboard: App["pasteFromClipboard"];
  // id: App["id"];
  onExportImage: App["onExportImage"];
  scrollToContent: App["scrollToContent"];
  addFiles: App["addFiles"];
  addElementsFromPasteOrLibrary: App["addElementsFromPasteOrLibrary"];
  togglePenMode: App["togglePenMode"];
  setActiveTool: App["setActiveTool"];
};

export type PointerDownState = Readonly<{
  // The first position at which pointerDown happened
  origin: Readonly<{ x: number; y: number }>;
  // Same as "origin" but snapped to the grid, if grid is on
  originInGrid: Readonly<{ x: number; y: number }>;
  // Scrollbar checks
  scrollbars: ReturnType<typeof isOverScrollBars>;
  // The previous pointer position
  lastCoords: { x: number; y: number };
  // map of original elements data
  originalElements: Map<string, NonDeleted<ExcalidrawElement>>;
  resize: {
    // Handle when resizing, might change during the pointer interaction
    handleType: MaybeTransformHandleType;
    // This is determined on the initial pointer down event
    isResizing: boolean;
    // This is determined on the initial pointer down event
    offset: { x: number; y: number };
    // This is determined on the initial pointer down event
    arrowDirection: "origin" | "end";
    // This is a center point of selected elements determined on the initial pointer down event (for rotation only)
    center: { x: number; y: number };
  };
  hit: {
    // The element the pointer is "hitting", is determined on the initial
    // pointer down event
    element: NonDeleted<ExcalidrawElement> | null;
    // The elements the pointer is "hitting", is determined on the initial
    // pointer down event
    allHitElements: NonDeleted<ExcalidrawElement>[];
    // This is determined on the initial pointer down event
    wasAddedToSelection: boolean;
    // Whether selected element(s) were duplicated, might change during the
    // pointer interaction
    hasBeenDuplicated: boolean;
    hasHitCommonBoundingBoxOfSelectedElements: boolean;
  };
  withCmdOrCtrl: boolean;
  drag: {
    // Might change during the pointer interaction
    hasOccurred: boolean;
    // Might change during the pointer interaction
    offset: { x: number; y: number } | null;
  };
  // We need to have these in the state so that we can unsubscribe them
  eventListeners: {
    // It's defined on the initial pointer down event
    onMove: null | ReturnType<typeof throttleRAF>;
    // It's defined on the initial pointer down event
    onUp: null | ((event: PointerEvent) => void);
    // It's defined on the initial pointer down event
    onKeyDown: null | ((event: KeyboardEvent) => void);
    // It's defined on the initial pointer down event
    onKeyUp: null | ((event: KeyboardEvent) => void);
  };
  boxSelection: {
    hasOccurred: boolean;
  };
  elementIdsToErase: {
    [key: ExcalidrawElement["id"]]: {
      opacity: ExcalidrawElement["opacity"];
      erase: boolean;
    };
  };
}>;

export type ExcalidrawImperativeAPI = {
  updateScene: InstanceType<typeof App>["updateScene"];
  updateLibrary: InstanceType<typeof Library>["updateLibrary"];
  resetScene: InstanceType<typeof App>["resetScene"];
  getSceneElementsIncludingDeleted: InstanceType<
    typeof App
  >["getSceneElementsIncludingDeleted"];
  getSceneElementsMapIncludingDeleted: InstanceType<
    typeof App
  >["getSceneElementsMapIncludingDeleted"];
  history: {
    clear: InstanceType<typeof App>["resetHistory"];
  };
  scrollToContent: InstanceType<typeof App>["scrollToContent"];
  getSceneElements: InstanceType<typeof App>["getSceneElements"];
  getAppState: () => InstanceType<typeof App>["state"];
  getFiles: () => InstanceType<typeof App>["files"];
  refresh: InstanceType<typeof App>["refresh"];
  setToast: InstanceType<typeof App>["setToast"];
  addFiles: (data: BinaryFileData[]) => void;
  readyPromise: ResolvablePromise<ExcalidrawImperativeAPI>;
  ready: true;
  id: string;
  setActiveTool: InstanceType<typeof App>["setActiveTool"];
  setCursor: InstanceType<typeof App>["setCursor"];
  resetCursor: InstanceType<typeof App>["resetCursor"];
  toggleMenu: InstanceType<typeof App>["toggleMenu"];
};

export type Device = Readonly<{
  isSmScreen: boolean;
  isMobile: boolean;
  isTouchScreen: boolean;
  canDeviceFitSidebar: boolean;
}>;

export type ElementsPendingErasure = Set<ExcalidrawElement["id"]>;

export interface ProjectPrintHeader {
  title: string;
  subTitle: string;
  remark: string;
  logo: { file: File | null; url: string };
  organizationName: string;
  date: Date;
}
export interface ProjectPrintOption {
  hasHeader: boolean;
  direction: "landscape"|"portrait";
  fitType: "contain"|"none";
  fitDirection: "x"|"y";
}