import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
// CHANGED:UPDATE 2022-10-18 #38
// import ExcalidrawApp from "./excalidraw-app";
import ExcalidrawApp from "src/excalidraw/extensions/app";

// import "src/excalidraw/excalidraw-app/pwa";
window.__EXCALIDRAW_SHA__ = import.meta.env.REACT_APP_GIT_SHA;
const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <ExcalidrawApp />
  </StrictMode>,
);
