import {
  ExcalidrawElement,
  ExcalidrawGenericElement,
  ExcalidrawTextContainer,
  ExcalidrawTextElement,
  ExcalidrawRectangleElement,
  FontFamilyValues,
  NonDeleted,
  TextAlign,
  VerticalAlign,
  TextDirection,
} from "../../element/types";
import {
  ArrowheadEx,
  ExcalidrawLinkElement,
  ExcalidrawTaskElement,
  ExcalidrawJobElement,
  ExcalidrawMilestoneElement,
  ExcalidrawCommentElement,
  ExcalidrawCommentableElement,
} from "./types"; // from extensions
import { getDefaultLineHeight, measureText, normalizeText } from "../../element/textElement";
import { getFontString, getUpdatedTimestamp } from "src/excalidraw/utils";
import { randomId, randomInteger } from "../../random";
import { newElementWith } from "../../element/mutateElement";
// CHANGED:ADD 2022-11-21 #164
import Calendar from "../calendar"; // from extensions
import ColorsEx from "src/excalidraw/extensions/constants/ColorsEx";
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_SIZE,
  DEFAULT_TEXT_ALIGN,
  DEFAULT_TEXT_DIRECTION,
  DEFAULT_VERTICAL_ALIGN,
  PRIORITY,
} from "src/excalidraw/constants";
import { MarkOptional } from "src/excalidraw/utility-types";
import { AppState } from "../../types";

// CHANGED:ADD 2022-12-2 #235
type ElementConstructorOptsEx = MarkOptional<
  Omit<ExcalidrawGenericElement, "id" | "type" | "isDeleted" | "updated" | "updatedBy">,
  | "strokeColor"
  | "backgroundColor"
  | "fillStyle"
  | "strokeWidth"
  | "strokeStyle"
  | "roundness"
  // | "roughness" // CHANGED:REMOVE 2024-02-16 #1666
  // | "opacity" // CHANGED:REMOVE 2024-02-16 #1666
  | "width"
  | "height"
  // | "angle" // CHANGED:REMOVE 2024-02-16 #1666
  | "groupIds"
  | "boundElements"
  | "seed"
  | "version"
  | "versionNonce"
  | "link"
  | "locked"
  | "isClosed"
  | "isVisible"
>;

// CHANGED:ADD 2022-12-2 #235
const _newElementBaseEx = <T extends ExcalidrawElement>(
  type: T["type"],
  {
    x,
    y,
    strokeColor = "#000000",
    backgroundColor = "transparent",
    fillStyle = "hachure",
    strokeWidth = 0,
    strokeStyle = "solid",
    // roughness = 0, // CHANGED:REMOVE 2024-02-16 #1666
    // opacity = 100, // CHANGED:REMOVE 2024-02-16 #1666
    width = 0,
    height = 0,
    // angle = 0, // CHANGED:REMOVE 2024-02-16 #1666
    groupIds = [],
    roundness = null,
    boundElements = null,
    link = null,
    locked = false,
    isClosed = false, // CHANGED:ADD 2023-02-28 #739
    isVisible = true, // CHANGED: ADD 2023-03-05 #740
    ...rest
  }: ElementConstructorOptsEx & Omit<Partial<ExcalidrawGenericElement>, "type">,
) => {
  const priority = PRIORITY[type];
  const element = {
    id: rest.id || randomId(),
    type,
    x,
    y,
    width,
    height,
    // angle, // CHANGED:REMOVE 2024-02-16 #1666
    strokeColor,
    backgroundColor,
    fillStyle,
    strokeWidth,
    strokeStyle,
    // roughness, // CHANGED:REMOVE 2024-02-16 #1666
    // opacity, // CHANGED:REMOVE 2024-02-16 #1666
    groupIds,
    roundness,
    seed: rest.seed ?? randomInteger(),
    version: rest.version || 1,
    versionNonce: rest.versionNonce ?? 0,
    isDeleted: false as false,
    boundElements,
    updated: getUpdatedTimestamp(),
    link,
    locked,
    priority,
    isClosed, // CHANGED:ADD 2023-02-28 #739
    isVisible, // CHANGED: ADD 2023-03-05 #740
  };
  return element;
};

// CHANGED:ADD 2022-12-13 #313
/** computes element x/y offset based on textAlign/verticalAlign */
const getTextElementPositionOffsets = (
  opts: {
    textAlign: ExcalidrawTextElement["textAlign"];
    verticalAlign: ExcalidrawTextElement["verticalAlign"];
  },
  metrics: {
    width: number;
    height: number;
  },
) => {
  return {
    x:
      opts.textAlign === "center"
        ? metrics.width / 2
        : opts.textAlign === "right"
        ? metrics.width
        : 0,
    y: opts.verticalAlign === "middle" ? metrics.height / 2 : 0,
  };
};

export const newTaskElement = (
  opts: {
    startArrowhead: ArrowheadEx | null;
    endArrowhead: ArrowheadEx | null;
    points?: ExcalidrawTaskElement["points"];
    jobId: string; // CHANGED: ADD 2023-03-01 #740
    jobOffsetY: number; // CHANGED: ADD 2023-03-01 #740
  } & ElementConstructorOptsEx,
  calendar: Calendar, // CHANGED:ADD 2022-11-21 #164
): NonDeleted<ExcalidrawTaskElement> => {
  // CHANGED:ADD 2022-11-01 #79
  const { startDate, endDate } = calendar.getPointDates(opts.x);
  return {
    ..._newElementBaseEx<ExcalidrawTaskElement>("task", opts),
    points: opts.points || [],
    startArrowhead: opts.startArrowhead,
    endArrowhead: opts.endArrowhead,
    // CHANGED:REMOVE 2023/2/9 #601
    //strokeWidth: 3, //CHANGED:ADD 2022-11-18 #158
    startDate, //CHANGED:ADD 2022-11-01 #79
    endDate, //CHANGED:ADD 2022-11-01 #79
    duration: 0, // CHANGED:ADD 2022-11-15 #114
    prevDependencies: [], // CHANGED:ADD 2022-11-15 #114
    nextDependencies: [], // CHANGED:ADD 2022-11-27 #208
    freeFloats: [], // CHANGED:ADD 2024-04-15 #1917
    strokeStyle: "solid", //CHANGED:ADD 2022-11-18 #190
    holidays: null, // CHANGED:ADD 2023-1-20 #382
    isCriticalPath: false, // CHANGED:ADD 2023-1-23 #455
    jobId: opts.jobId, // CHANGED: ADD 2023-03-01 #740
    jobOffsetY: opts.jobOffsetY, // CHANGED: ADD 2023-03-01 #740
    boundElementsEx: [],
    memo: "", //CHANGED:ADD 2024-05-06 #1848
    created: getUpdatedTimestamp(), // CHANGED:ADD 2024-02-17 #1677
  };
};

// CHANGED:ADD 2022-12-7 #157
export const newMilestoneElement = (
  opts: {
    width: number;
    jobId: string, // CHANGED: ADD 2023-03-01 #740
    jobOffsetY: number, // CHANGED: ADD 2023-03-01 #740
  } & ElementConstructorOptsEx,
  calendar: Calendar,
): NonDeleted<ExcalidrawMilestoneElement> => {
  const { startDate } = calendar.getPointDates(opts.x + opts.width / 2);
  return {
    ..._newElementBaseEx<ExcalidrawMilestoneElement>("milestone", opts),
    strokeWidth: 8, // CHANGED:ADD 2023-08-22 #924
    date: startDate,
    duration: 0,
    prevDependencies: [],
    nextDependencies: [],
    freeFloats: [], // CHANGED:ADD 2024-04-15 #1917
    strokeColor: ColorsEx.lineColor.black,
    backgroundColor: ColorsEx.backgroundColor.milestone,
    fillStyle: "solid",
    isCriticalPath: false, // CHANGED:ADD 2023-1-23 #455
    jobId: opts.jobId, // CHANGED: ADD 2023-03-01 #740
    jobOffsetY: opts.jobOffsetY, // CHANGED: ADD 2023-03-01 #740
    boundElementsEx: [],
    created: getUpdatedTimestamp(), // CHANGED:ADD 2024-02-17 #1677
  };
};

// CHANGED:ADD 2022-11-02 #64
export const newLinkElement = (
  opts: {
    points?: ExcalidrawLinkElement["points"];
  } & ElementConstructorOptsEx,
  pointerDirection: AppState["currentItemPointerDirection"], //CHANGED:ADD 2024-03-11 #1749
): NonDeleted<ExcalidrawLinkElement> => {
  return {
    ..._newElementBaseEx<ExcalidrawLinkElement>("link", opts),
    points: opts.points || [],
    startBinding: null,
    endBinding: null,
    strokeStyle: "dashed",
    //CHANGED:REMOVE 2023-2-9 #601
    //strokeWidth: 2, //CHANGED:ADD 2022-11-18 #158
    //strokeColor: "#000000", //CHANGED:REMOVE 2023-1-27 #532
    duration: 0, //CHANGED:ADD 2022/01/19 #390
    isCriticalPath: false, // CHANGED:ADD 2023-1-23 #455
    pointerDirection, //CHANGED:ADD 2024-03-11 #1749
  };
};

// CHANGED:ADD 2022-12-5 #250
export const newJobElement = (
  opts: ElementConstructorOptsEx,
): NonDeleted<ExcalidrawJobElement> => {
  return {
    ..._newElementBaseEx<ExcalidrawJobElement>("job", opts),
    fillStyle: opts.fillStyle ? opts.fillStyle : "hachure",
    strokeWidth: 1,
    isCompressed: false, // CHANGED: ADD 2023-02-23 #740
    originalHeight: opts.height!, // CHANGED: ADD 2023-02-23 #740
  };
};

// CHANGED:ADD 2022-12-13 #310
export const newCalendarBackgroundElement = (
  opts: ElementConstructorOptsEx,
): NonDeleted<ExcalidrawRectangleElement> => {
  return {
    ..._newElementBaseEx<ExcalidrawRectangleElement>("rectangle", opts),
  };
};

// CHANGED:ADD 2022-12-13 #313
export const newTextElementEx = (
  opts: {
    text: string;
    fontSize?: number;
    fontFamily?: FontFamilyValues;
    textAlign?: TextAlign;
    horizontalAlign?: TextAlign, // CHANGED:ADD 2024-03-27 #1881
    verticalAlign?: VerticalAlign;
    containerId?: ExcalidrawTextContainer["id"];
    lineHeight?: ExcalidrawTextElement["lineHeight"];
    originalText: string;
    textBorderNone?: boolean, // CHANGED:ADD 2024-03-27 #1790
    textBorderOpacity?: number, // CHANGED:ADD 2024-03-27 #1779
    textDirection?: TextDirection, // CHANGED:ADD 2024/02/01 #1510
  } & ElementConstructorOptsEx,
): NonDeleted<ExcalidrawTextElement> => {
  const fontFamily = opts.fontFamily || DEFAULT_FONT_FAMILY;
  const fontSize = opts.fontSize || DEFAULT_FONT_SIZE;
  const lineHeight = opts.lineHeight || getDefaultLineHeight(fontFamily);
  const text = normalizeText(opts.text);
  const originalText = normalizeText(opts.originalText);
  const metrics = measureText(
    text,
    getFontString({ fontFamily, fontSize }),
    lineHeight,
  );
  const textAlign = opts.textAlign || DEFAULT_TEXT_ALIGN;
  const horizontalAlign = opts.horizontalAlign || DEFAULT_TEXT_ALIGN;
  const verticalAlign = opts.verticalAlign || DEFAULT_VERTICAL_ALIGN;
  const textDirection = opts.textDirection || DEFAULT_TEXT_DIRECTION; // CHANGED:ADD 2024/02/01 #1510
  const offsets = getTextElementPositionOffsets(
    { textAlign, verticalAlign },
    metrics,
  );

  const textElement = newElementWith(
    {
      ..._newElementBaseEx<ExcalidrawTextElement>("text", opts),
      text,
      fontSize,
      fontFamily,
      textAlign,
      horizontalAlign, // CHANGED:ADD 2024-03-27 #1881
      verticalAlign,
      x: opts.x - offsets.x,
      y: opts.y - offsets.y,
      width: metrics.width,
      height: metrics.height,
      baseline: metrics.baseline,
      containerId: opts.containerId || null,
      originalText,
      lineHeight,
      isCompressed: false, // CHANGED:ADD 2023-03-15 #740
      textBorderNone: opts.textBorderNone, // CHANGED:ADD 2024-03-27 #1790
      textBorderOpacity: opts.textBorderOpacity, // CHANGED:ADD 2024-03-27 #1779
      textDirection, // CHANGED:ADD 2024/02/01 #1510
    },
    {},
  );
  return textElement;
};

// CHANGED:ADD 2023-12-23 #1138
export const newCommentElement = (
  opts: ElementConstructorOptsEx & {
    id: string;
    commentElementId: ExcalidrawCommentableElement["id"];
    isVisible: boolean;
  },
): NonDeleted<ExcalidrawCommentElement> => {
  return {
    ..._newElementBaseEx<ExcalidrawCommentElement>("comment", opts),
    commentElementId: opts.commentElementId,
    isVisible: opts.isVisible,
  };
};