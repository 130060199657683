import { CommentIcon, LibraryIcon } from "src/excalidraw/components/icons";
import "./DefaultSidebar.scss";
import { t } from "src/excalidraw/i18n";
import { useExcalidrawAppState, useExcalidrawSetAppState } from "src/excalidraw/components/App";

export const DefaultSidebar: React.FC<{}> = () => {
  const appState = useExcalidrawAppState();
  const setAppState = useExcalidrawSetAppState();

  return (
    <div
      className={"layer-ui__default_sidebar"}
    >
      {!appState.viewModeEnabled &&
        <div className="layer-ui__default_sidebar__header__buttons">
          <button
            className="DefaultSidebar__library-btn"
            onClick={() => setAppState({ openSidebar: "library" })}
            aria-label={t("buttons.library")}
          >
            {LibraryIcon}
          </button>
        </div>
      }
    </div>
  );
};
