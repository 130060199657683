import React, { Fragment, useEffect, useRef } from "react";
import { ProjectPrintHeader, ProjectPrintOption } from "src/excalidraw//types";
import { Popover, Transition } from "@headlessui/react";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { t } from "src/excalidraw/i18n";
import { formatDate } from "src/utils/dateUtils";
import { HeaderIcon } from "src/conpath/components/icons";
import Checkbox from "src/conpath/components/CheckBox";

export const PrintHeaderElement = ({
  printHeader,
  onUpdate,
}: {
  printHeader: ProjectPrintHeader;
  onUpdate: (param: ProjectPrintHeader) => void;
}) => {
  return (
    <div className="print-header__wrapper">
      <div className="print-header__content1">
        <p className="print-header__content1__title">{printHeader.title}</p>
        <p className="print-header__content1__sub-title">
          {printHeader.subTitle}
        </p>
      </div>
      <div className="print-header__content2">
        <p className="text-[6px]">{printHeader.remark}</p>
      </div>
      <div className="print-header__content3">
        <div>
          {printHeader.logo.url ? (
            <img
              src={
                printHeader.logo.url
              }
            />
          ) : (
            <div />
          )}
          <div>
            <p className="text-[8px]">{printHeader.organizationName}</p>
            <p className="print-header__date">{formatDate(printHeader.date)}</p>
          </div>
        </div>
      </div>
      <div className="print-header__content4">
        <div>
          <div className="w-[33%]">承認者</div>
          <div className="w-[33%]">確認者</div>
          <div className="w-[33%]">作成者</div>
        </div>
        <div>
          <div className="w-[33%]"></div>
          <div className="w-[33%]"></div>
          <div className="w-[33%]"></div>
        </div>
      </div>
    </div>
  );
};

export const PrintHeaderEditPopover = ({
  printHeader,
  onUpdate,
  printOption,
  onUpdatePrintOption,
}: {
  printHeader: ProjectPrintHeader;
  onUpdate: (param: ProjectPrintHeader) => void;
  printOption: ProjectPrintOption;
  onUpdatePrintOption: <K extends keyof ProjectPrintOption>(
    key: K,
    value: ProjectPrintOption[K],
  ) => void;
}) => {
  const headerLogoRef = useRef<HTMLInputElement>(null);
  const onChangeLogo = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (!event.target.files) {
        return;
      }

      const file = event.target.files[0];
      const { size } = file;
      if (size > 12 * 1_000_000) {
        alert("12MB以上の画像は選択できません。");
        return;
      }

      const imageURL: string = URL.createObjectURL(file); //preview
      const logo: ProjectPrintHeader["logo"] = {
        url: imageURL,
        file: file,
      };
      onUpdate({ ...printHeader, logo });
    } finally {
      event.target.value = "";
    }
  };

  return (
    <React.Fragment>
      <Popover>
        <React.Fragment>
          <Checkbox
            props={{
              checked: printOption.hasHeader,
              size: "small",
              onClick: () => {
                onUpdatePrintOption("hasHeader", !printOption.hasHeader);
              },
            }}
          />
          <span style={{ opacity: printOption.hasHeader ? 1 : 0.3 }}>
            ヘッダー
          </span>
          <Popover.Button
            style={{
              opacity: printOption.hasHeader ? 1 : 0.3,
              pointerEvents: printOption.hasHeader ? "auto" : "none",
            }}
            className="ExportDialog__header-icon primary"
          >
            <HeaderIcon size="20" />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className={"ExportDialog__header-panel"}>
              <div className="ExportDialog__header-panel-box">
                <label>タイトル名</label>
                <input
                  className="input"
                  placeholder="タイトル"
                  value={printHeader?.title}
                  onChange={(e) => {
                    onUpdate({
                      ...printHeader,
                      title: e.target.value,
                    });
                  }}
                />
                <input
                  className="input"
                  placeholder="サブタイトル"
                  value={printHeader?.subTitle}
                  onChange={(e) => {
                    onUpdate({
                      ...printHeader,
                      subTitle: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="ExportDialog__header-panel-box">
                <label>備考</label>
                <textarea
                  className="input"
                  placeholder="備考"
                  value={printHeader?.remark}
                  onChange={(e) => {
                    onUpdate({
                      ...printHeader,
                      remark: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="ExportDialog__header-panel-box">
                <label>組織名</label>
                <input
                  className="input"
                  placeholder="組織名"
                  value={printHeader?.organizationName}
                  onChange={(e) => {
                    onUpdate({
                      ...printHeader,
                      organizationName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="ExportDialog__header-panel-box">
                <label>ロゴ</label>
                <input
                  hidden
                  name="file"
                  type="file"
                  accept="image/*"
                  ref={headerLogoRef}
                  onChange={(e) => onChangeLogo(e)}
                />
                <div>
                  {printHeader.logo.url ? (
                    <div className="ExportDialog__header-logo">
                      <img src={printHeader.logo.url} />
                      <div className="ExportDialog__header-logo-btns">
                        <div onClick={() => headerLogoRef.current?.click()}>
                          変更する
                        </div>
                        <div
                          onClick={() =>
                            onUpdate({
                              ...printHeader,
                              logo: { file: null, url: "" },
                            })
                          }
                        >
                          リセット
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="ExportDialog__header-logo-upload"
                      onClick={() => headerLogoRef.current?.click()}
                    >
                      画像をアップロード
                    </div>
                  )}
                </div>
              </div>
              <div className="ExportDialog__header-panel-box">
                <label>作成日</label>
                <DatePicker
                  className="ExportDialog__header-panel-date"
                  dateFormat="yyyy/MM/dd"
                  locale="ja"
                  dateFormatCalendar={t("labels.editTask.rangeCalendarHeader")}
                  placeholderText="作成日"
                  selected={printHeader.date}
                  onChange={(date: Date) => onUpdate({ ...printHeader, date })}
                />
              </div>
            </Popover.Panel>
          </Transition>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
};
