import {
  ExcalidrawElement,
  ExcalidrawLinearElement,
  ExcalidrawTextElement,
  Arrowhead,
  NonDeletedExcalidrawElement,
  ExcalidrawFreeDrawElement,
  ExcalidrawImageElement,
  ExcalidrawTextElementWithContainer,
  NonDeletedSceneElementsMap,
  ElementsMap,
} from "../element/types";
import {
  ArrowheadEx, // CHANGED:ADD 2022-12-12 #290
  ExcalidrawTaskElement, // CHANGED:ADD 2022-10-28 #14
} from "../extensions/element/types"; // from extensions
import {
  isTextElement,
  isLinearElement,
  isFreeDrawElement,
  isInitializedImageElement,
  isArrowElement,
  hasBoundTextElement,
} from "../element/typeChecks";
import {
  isJobElement, // CHANGED:ADD 2022-11-18 #175
  isTaskElement, // CHANGED:ADD 2022-10-28 #14
  isLinkElement, // CHANGED:ADD 2022-11-2 #64
  isGraphElement, // CHANGED:ADD 2023/01/17 #390
  isMilestoneElement,
  isJobTextElement,
  isCommentElement, // CHANGED:ADD 2022-12-13 #289
} from "../extensions/element/typeChecks"; // from extensions
import {
  getElementAbsoluteCoords,
  getElementBounds, // CHANGED:ADD 2023-03-28 #708-2
} from "../element/bounds";
import { RoughCanvas } from "roughjs/bin/canvas";
import { Drawable } from "roughjs/bin/core";
import { RoughSVG } from "roughjs/bin/svg";

import {
  RenderableElementsMap,
  SVGRenderConfig,
  StaticCanvasRenderConfig,
} from "../scene/types";
import {
  distance,
  getFontString,
  getFontFamilyString,
  isRTL,
  isTransparent,
} from "../utils";
import { getCornerRadius, isPathALoop, isRightAngle } from "../math";
import rough from "roughjs/bin/rough";
import {
  AppState,
  StaticCanvasAppState,
  BinaryFiles,
  Zoom,
  InteractiveCanvasAppState,
} from "../types";
import { getDefaultAppState } from "../appState";
import {
  BOUND_TEXT_PADDING,
  MAX_DECIMALS_FOR_SVG_EXPORT,
  MIME_TYPES,
  SVG_NS,
  ELEMENT_CLOSED_OPACITY,
  DEPENDENCY_ELEMENT_SHADOW_BLUR,
  NON_DEPENDENCY_ELEMENT_OPACITY,
  DEPENDENCY_ELEMENT_SHADOW_OFFSET_X,
  DEPENDENCY_ELEMENT_SHADOW_OFFSET_Y,
  JOB_ACCORDION_BUTTON_SIZE,
  JOB_ACCORDION_BUTTON_ROUND,
  LINK_LINE_WIDTH,
  CLOSED_TASK_LINE_WIDTH,
  CLOSED_LINK_LINE_WIDTH,
  CLITICAL_PATH_TASK_LINE_WIDTH,
  GRID_SIZE,
} from "../constants";
import { getStroke, StrokeOptions } from "perfect-freehand";
import {
  getBoundTextElement,
  getBoundTextMaxHeight,
  getBoundTextMaxWidth,
  getContainerCoords,
  getContainerElement,
  getLineHeightInPx,
  getTextWidth,
} from "../element/textElement";
import { LinearElementEditor } from "../element/linearElementEditor";
import { ShapeCache } from "../scene/ShapeCache";

// using a stronger invert (100% vs our regular 93%) and saturate
// as a temp hack to make images in dark theme look closer to original
// color scheme (it's still not quite there and the colors look slightly
// desatured, alas...)

// CHANGED:ADD 2022-10-19 #21
import { viewportCoordsToSceneCoords } from "../utils";
import { TaskElementEditor } from "src/excalidraw/extensions/element/taskElementEditor";
// CHANGED:ADD 2022-12-13 #289
import { MilestoneElementEditor } from "src/excalidraw/extensions/element/milestoneElementEditor";
import { LinkElementEditor } from "src/excalidraw/extensions/element/linkElementEditor";
import ColorsEx from "src/excalidraw/extensions/constants/ColorsEx";

const IMAGE_INVERT_FILTER = "invert(100%) hue-rotate(180deg) saturate(1.25)";

const defaultAppState = getDefaultAppState();

const isPendingImageElement = (
  element: ExcalidrawElement,
  renderConfig: StaticCanvasRenderConfig,
) =>
  isInitializedImageElement(element) &&
  !renderConfig.imageCache.has(element.fileId);

const shouldResetImageFilter = (
  element: ExcalidrawElement,
  renderConfig: StaticCanvasRenderConfig,
  appState: StaticCanvasAppState,
) => {
  return (
    appState.theme === "dark" &&
    isInitializedImageElement(element) &&
    !isPendingImageElement(element, renderConfig) &&
    renderConfig.imageCache.get(element.fileId)?.mimeType !== MIME_TYPES.svg
  );
};

const getCanvasPadding = (element: ExcalidrawElement) => {
  return element.type === "freedraw"
    ? element.strokeWidth * 12
    : element.type === "task"
    ? 50
    : 20;
};// CHANGED:CHANGE 2023-08-22 #924

export interface ExcalidrawElementWithCanvas {
  element: ExcalidrawElement | ExcalidrawTextElement;
  canvas: HTMLCanvasElement;
  theme: AppState["theme"];
  scale: number;
  zoomValue: AppState["zoom"]["value"];
  canvasOffsetX: number;
  canvasOffsetY: number;
  boundTextElementVersion: number | null;
}

const cappedElementCanvasSize = (
  element: NonDeletedExcalidrawElement,
  elementsMap: ElementsMap,
  zoom: Zoom,
): {
  width: number;
  height: number;
  scale: number;
} => {
  // these limits are ballpark, they depend on specific browsers and device.
  // We've chosen lower limits to be safe. We might want to change these limits
  // based on browser/device type, if we get reports of low quality rendering
  // on zoom.
  //
  // ~ safari mobile canvas area limit
  const AREA_LIMIT = 16777216;
  // ~ safari width/height limit based on developer.mozilla.org.
  const WIDTH_HEIGHT_LIMIT = 32767;

  const padding = getCanvasPadding(element);

  const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);
  const elementWidth =
    isLinearElement(element) || isFreeDrawElement(element)
      ? distance(x1, x2)
      : element.width;
  const elementHeight =
    isLinearElement(element) || isFreeDrawElement(element)
      ? distance(y1, y2)
      : element.height;

  let width = elementWidth * window.devicePixelRatio + padding * 2;
  let height = elementHeight * window.devicePixelRatio + padding * 2;

  let scale: number = zoom.value;

  // rescale to ensure width and height is within limits
  if (
    width * scale > WIDTH_HEIGHT_LIMIT ||
    height * scale > WIDTH_HEIGHT_LIMIT
  ) {
    scale = Math.min(WIDTH_HEIGHT_LIMIT / width, WIDTH_HEIGHT_LIMIT / height);
  }

  // rescale to ensure canvas area is within limits
  if (width * height * scale * scale > AREA_LIMIT) {
    scale = Math.sqrt(AREA_LIMIT / (width * height));
  }

  width = Math.floor(width * scale);
  height = Math.floor(height * scale);

  return { width, height, scale };
};

const generateElementCanvas = (
  element: NonDeletedExcalidrawElement,
  elementsMap: RenderableElementsMap,
  zoom: Zoom,
  renderConfig: StaticCanvasRenderConfig,
  appState: StaticCanvasAppState,
): ExcalidrawElementWithCanvas => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d")!;
  const padding = getCanvasPadding(element);

  const { width, height, scale } = cappedElementCanvasSize(element, elementsMap, zoom);

  canvas.width = width;
  canvas.height = height;

  let canvasOffsetX = 0;
  let canvasOffsetY = 0;

  if (isLinearElement(element) || isFreeDrawElement(element)) {
    const [x1, y1] = getElementAbsoluteCoords(element, elementsMap);

    canvasOffsetX =
      element.x > x1
        ? distance(element.x, x1) * window.devicePixelRatio * scale
        : 0;

    canvasOffsetY =
      element.y > y1
        ? distance(element.y, y1) * window.devicePixelRatio * scale
        : 0;

    context.translate(canvasOffsetX, canvasOffsetY);
    // CHANGED:ADD 2022-10-28 #14
  } else if (isTaskElement(element)) {
    const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);

    canvas.width =
      distance(x1, x2) * window.devicePixelRatio * zoom.value +
      padding * zoom.value * 2;
    canvas.height =
      distance(y1, y2) * window.devicePixelRatio * zoom.value +
      padding * zoom.value * 2;

    canvasOffsetX =
      element.x > x1
        ? distance(element.x, x1) * window.devicePixelRatio * zoom.value
        : 0;

    canvasOffsetY =
      element.y > y1
        ? distance(element.y, y1) * window.devicePixelRatio * zoom.value
        : 0;

    context.translate(canvasOffsetX, canvasOffsetY);
    context.lineCap = "butt"; // CHANGED:ADD 2023-08-22 #924
    // CHANGED:ADD 2022-11-2 #64
  } else if (isLinkElement(element)) {
    const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);

    canvas.width =
      distance(x1, x2) * window.devicePixelRatio * zoom.value +
      padding * zoom.value * 2;
    canvas.height =
      distance(y1, y2) * window.devicePixelRatio * zoom.value +
      padding * zoom.value * 2;

    canvasOffsetX =
      element.x > x1
        ? distance(element.x, x1) * window.devicePixelRatio * zoom.value
        : 0;

    canvasOffsetY =
      element.y > y1
        ? distance(element.y, y1) * window.devicePixelRatio * zoom.value
        : 0;

    context.translate(canvasOffsetX, canvasOffsetY);
  } else {
    canvas.width =
      element.width * window.devicePixelRatio * zoom.value +
      padding * zoom.value * 2;
    canvas.height =
      element.height * window.devicePixelRatio * zoom.value +
      padding * zoom.value * 2;
  }

  context.save();
  context.translate(padding * scale, padding * scale);
  context.scale(
    window.devicePixelRatio * scale,
    window.devicePixelRatio * scale,
  );

  const rc = rough.canvas(canvas);

  // in dark theme, revert the image color filter
  if (shouldResetImageFilter(element, renderConfig, appState)) {
    context.filter = IMAGE_INVERT_FILTER;
  }

  drawElementOnCanvas(
    element,
    elementsMap,
    rc,
    context,
    renderConfig,
    appState
  );
  context.restore();

  return {
    element,
    canvas,
    theme: appState.theme,
    scale,
    zoomValue: zoom.value,
    canvasOffsetX,
    canvasOffsetY,
    boundTextElementVersion:
      getBoundTextElement(element, elementsMap)?.version || null,
  };
};

export const DEFAULT_LINK_SIZE = 14;

const IMAGE_PLACEHOLDER_IMG = document.createElement("img");
IMAGE_PLACEHOLDER_IMG.src = `data:${MIME_TYPES.svg},${encodeURIComponent(
  `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="image" class="svg-inline--fa fa-image fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#888" d="M464 448H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v288c0 26.51-21.49 48-48 48zM112 120c-30.928 0-56 25.072-56 56s25.072 56 56 56 56-25.072 56-56-25.072-56-56-56zM64 384h384V272l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L208 320l-55.515-55.515c-4.686-4.686-12.284-4.686-16.971 0L64 336v48z"></path></svg>`,
)}`;

const IMAGE_ERROR_PLACEHOLDER_IMG = document.createElement("img");
IMAGE_ERROR_PLACEHOLDER_IMG.src = `data:${MIME_TYPES.svg},${encodeURIComponent(
  `<svg viewBox="0 0 668 668" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;strokeLinejoin:round;stroke-miterlimit:2"><path d="M464 448H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v288c0 26.51-21.49 48-48 48ZM112 120c-30.928 0-56 25.072-56 56s25.072 56 56 56 56-25.072 56-56-25.072-56-56-56ZM64 384h384V272l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L208 320l-55.515-55.515c-4.686-4.686-12.284-4.686-16.971 0L64 336v48Z" style="fill:#888;fill-rule:nonzero" transform="matrix(.81709 0 0 .81709 124.825 145.825)"/><path d="M256 8C119.034 8 8 119.033 8 256c0 136.967 111.034 248 248 248s248-111.034 248-248S392.967 8 256 8Zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676ZM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676Z" style="fill:#888;fill-rule:nonzero" transform="matrix(.30366 0 0 .30366 506.822 60.065)"/></svg>`,
)}`;

// CHANGED:ADD 2023/08/25 #932
const MILESTONE_IMG = document.createElement("img");
MILESTONE_IMG.src = `data:${MIME_TYPES.svg}, ${encodeURIComponent(
  `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="36.137" viewBox="0 0 20 36.137">
  <path d="M20522,22103.137a.847.847,0,0,1-.826-.613l-2.473-8.023a9.988,9.988,0,0,1,0-18.863l2.473-8.02a.84.84,0,0,1,.826-.617.865.865,0,0,1,.836.617l2.471,8.02a9.992,9.992,0,0,1,0,18.863l-2.471,8.023A.866.866,0,0,1,20522,22103.137Zm0-21.484a3.416,3.416,0,1,0,3.41,3.418A3.425,3.425,0,0,0,20522,22081.652Z" transform="translate(-20512 -22067)" fill="#46aadf"/>
</svg>`,
)}`;

// CHANGED: ADD 2023-12-21 #1138
const COMMENT_IMG = document.createElement("img");
COMMENT_IMG.src = `data:${MIME_TYPES.svg}, ${encodeURIComponent(
    `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="24.518" viewBox="0 0 21 24.518">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_23417" name="Rectangle 23417" width="18" height="18" fill="none" stroke="#000" strokeWidth="1"/>
      </clipPath>
    </defs>
    <g id="Group_12834" name="Group 12834" transform="translate(-13752.5 -20471.984) rotate(180)">
      <path id="Union_6" name="Union 6" d="M-467.447-357.158l-2.253-3.274H-475a2,2,0,0,1-2-2v-16a2,2,0,0,1,2-2h16a2,2,0,0,1,2,2v16a2,2,0,0,1-2,2h-5.3l-2.253,3.274a.542.542,0,0,1-.447.244A.54.54,0,0,1-467.447-357.158Z" transform="translate(-13296 -20115.57)" fill="#fff" stroke="#000" strokeWidth="1"/>
      <g id="Group_12827" name="Group 12827" transform="translate(-13754 -20477) rotate(180)" clipPath="url(#clip-path)">
        <path id="Path_1702" name="Path 1702" d="M12.6,18.24l-2.312,1.647V18.24H8.61a.7.7,0,0,1-.7-.7V11.072a.7.7,0,0,1,.7-.7H18.743a.7.7,0,0,1,.7.7v6.472a.7.7,0,0,1-.7.7Z" transform="translate(-4.676 -6.131)" fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="1"/>
        <line id="Line_170" name="Line 170" x2="6.994" transform="translate(5.464 6.985)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
        <line id="Line_171" name="Line 171" x2="5.834" transform="translate(5.464 9.409)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"/>
      </g>
    </g>
  </svg>`,
)}`;

const drawImagePlaceholder = (
  element: ExcalidrawImageElement,
  context: CanvasRenderingContext2D,
  zoomValue: AppState["zoom"]["value"],
) => {
  context.fillStyle = "#E7E7E7";
  context.fillRect(0, 0, element.width, element.height);

  const imageMinWidthOrHeight = Math.min(element.width, element.height);

  const size = Math.min(
    imageMinWidthOrHeight,
    Math.min(imageMinWidthOrHeight * 0.4, 100),
  );

  context.drawImage(
    element.status === "error"
      ? IMAGE_ERROR_PLACEHOLDER_IMG
      : IMAGE_PLACEHOLDER_IMG,
    element.width / 2 - size / 2,
    element.height / 2 - size / 2,
    size,
    size,
  );
};

const drawElementOnCanvas = (
  element: NonDeletedExcalidrawElement,
  elementsMap: RenderableElementsMap,
  rc: RoughCanvas,
  context: CanvasRenderingContext2D,
  renderConfig: StaticCanvasRenderConfig,
  appState: StaticCanvasAppState,
) => {
  context.globalAlpha = (element.opacity || 100) / 100;
  if (element.isClosed) {
    context.globalAlpha = ELEMENT_CLOSED_OPACITY;
  }
  switch (element.type) {
    case "job": // CHANGED:ADD 2022-10-25 #59
    case "rectangle":
    case "diamond":
    case "ellipse": {
      context.lineJoin = "round";
      context.lineCap = "round";
      rc.draw(ShapeCache.get(element)!);
      break;
    }
    case "comment": // CHANGED: ADD 2023-12-21 #1138  
      if (element.isVisible) {
        context.drawImage(
          COMMENT_IMG,
          0, /* hardcoded for the selection box*/
          10,
          24,
          30,
        );
      }
      break;
    case "milestone": // CHANGED:ADD 2022-12-7 #157
      // CHANGED:UPDATE 2023/08/25 #932
      context.drawImage(
        MILESTONE_IMG,
        4 /* hardcoded for the selection box*/,
        -6,
        24,
        44,
      );
      break;
    // CHANGED:ADD 2023-08-22 #924
    // CHANGED:UPDATE 2024-04-16 #1934
    case "task": {
      context.lineJoin = "round";
      context.lineCap = "butt";
      const shapes = ShapeCache.get(element);
      shapes?.forEach((shape, index) => {
        if (element.endArrowhead === "arrow" && (shapes.length - 2 <= index)) {
          context.lineCap = "round";
        }
        rc.draw(shape);
      });
      context.save();
      const text = element.duration >= 0 ? String(element.duration) : null;
      if (text) {
        const fontSize = 16;
        const font = getFontString({ fontFamily: 2, fontSize });

        context.font = font;
        context.fillStyle = element.endArrowhead === "dot" || element.endArrowhead === "dot_small"
          ? "#FFFFFF"
          : "#000000";
        const width = getTextWidth(text, font);
        const x = element.endArrowhead === "dot" || element.endArrowhead === "dot_small"
          ? element.width - width / 1.9
          : element.width / 2 - width / 1.9;
        const y = element.endArrowhead === "dot" || element.endArrowhead === "dot_small"
          ? fontSize * 0.38
          : fontSize * 0.38 + 18;
        context.fillText(text, x, y);
      }
      context.restore();
      break;
    }
    case "link": { // CHANGED:ADD 2022-11-2 #64
      context.lineJoin = "bevel";
      context.lineCap = "butt";

      ShapeCache.get(element)!.forEach((shape) => {
        rc.draw(shape);
      });
      break;
    }
    case "arrow":
    case "line": {
      context.lineJoin = "round";
      context.lineCap = "round";

      ShapeCache.get(element)!.forEach((shape) => {
        rc.draw(shape);
      });
      break;
    }
    case "freedraw": {
      // Draw directly to canvas
      context.save();
      context.fillStyle = element.strokeColor;

      const path = getFreeDrawPath2D(element) as Path2D;
      const fillShape = ShapeCache.get(element);

      if (fillShape) {
        rc.draw(fillShape);
      }

      context.fillStyle = element.strokeColor;
      context.fill(path);

      context.restore();
      break;
    }
    case "image": {
      const img = isInitializedImageElement(element)
        ? renderConfig.imageCache.get(element.fileId)?.image
        : undefined;
      if (img != null && !(img instanceof Promise)) {
        context.drawImage(
          img,
          0 /* hardcoded for the selection box*/,
          0,
          element.width,
          element.height,
        );
      } else {
        drawImagePlaceholder(element, context, appState.zoom.value);
      }
      break;
    }
    default: {
      if (isTextElement(element) || isJobTextElement(element)) {
        const rtl = isRTL(element.text);
        const shouldTemporarilyAttach = rtl && !context.canvas.isConnected;
        if (shouldTemporarilyAttach) {
          // to correctly render RTL text mixed with LTR, we have to append it
          // to the DOM
          document.body.appendChild(context.canvas);
        }
        context.canvas.setAttribute("dir", rtl ? "rtl" : "ltr");
        context.save();
        // CHANGED:ADD 2023/08/29 #955
        const container = getContainerElement(element, elementsMap);
        const isBoundTextOfTask = container && isTaskElement(container);
        if (isBoundTextOfTask && element.text.length > 0) {
          strokeBoundTextRect(context, element, container, renderConfig);
          context.restore();
        }
        context.font = getFontString(element);
        context.fillStyle = isBoundTextOfTask ? "#000000" : element.strokeColor;// CHANGED:UPDATE 2023/08/29 #955
        context.textAlign = element.textAlign as CanvasTextAlign;

        // Canvas does not support multiline text by default
        const lines = element.text.replace(/\r\n?/g, "\n").split("\n");

        const horizontalOffset =
          element.textAlign === "center"
            ? element.width / 2
            : element.textAlign === "right"
            ? element.width
            : 0;
        const lineHeightPx = getLineHeightInPx(
          element.fontSize,
          element.lineHeight,
        );
        const verticalOffset = element.height - element.baseline;
        for (let index = 0; index < lines.length; index++) {
          context.fillText(
            lines[index],
            horizontalOffset,
            (index + 1) * lineHeightPx - verticalOffset,
          );
        }
        context.restore();
        if (shouldTemporarilyAttach) {
          context.canvas.remove();
        }
      } else {
        throw new Error(`Unimplemented type ${element.type}`);
      }
    }
  }
  context.globalAlpha = 1;
};

export const elementWithCanvasCache = new WeakMap<
  ExcalidrawElement,
  ExcalidrawElementWithCanvas
>();

const generateElementWithCanvas = (
  element: NonDeletedExcalidrawElement,
  elementsMap: RenderableElementsMap,
  renderConfig: StaticCanvasRenderConfig,
  appState: StaticCanvasAppState,
) => {
  const zoom: Zoom = renderConfig ? appState.zoom : defaultAppState.zoom;
  const prevElementWithCanvas = elementWithCanvasCache.get(element);
  const shouldRegenerateBecauseZoom =
    prevElementWithCanvas &&
    prevElementWithCanvas.zoomValue !== zoom.value &&
    !appState?.shouldCacheIgnoreZoom;
  const boundTextElementVersion =
    getBoundTextElement(element, elementsMap)?.version || null;

  if (
    !prevElementWithCanvas ||
    shouldRegenerateBecauseZoom ||
    prevElementWithCanvas.theme !== appState.theme ||
    prevElementWithCanvas.boundTextElementVersion !== boundTextElementVersion
  ) {
    const elementWithCanvas = generateElementCanvas(
      element,
      elementsMap,
      zoom,
      renderConfig,
      appState,
    );

    elementWithCanvasCache.set(element, elementWithCanvas);

    return elementWithCanvas;
  }
  return prevElementWithCanvas;
};

const drawElementFromCanvas = (
  elementWithCanvas: ExcalidrawElementWithCanvas,
  context: CanvasRenderingContext2D,
  renderConfig: StaticCanvasRenderConfig,
  appState: StaticCanvasAppState,
  allElementsMap: NonDeletedSceneElementsMap,
) => {
  const element = elementWithCanvas.element;
  const padding = getCanvasPadding(element);
  const zoom = elementWithCanvas.scale;
  let [x1, y1, x2, y2] = getElementAbsoluteCoords(element, allElementsMap);
  let [_x1, _y1, _x2, _y2] = getElementBounds(element, allElementsMap); // CHANGED:ADD 2023-03-28 #708-2

  // CHANGED:REMOVE 2024-01-24 #1526
  // CHANGED:ADD 2023-03-28 #708-2
  // if (
  //   isTaskElement(element) ||
  //   isLinkElement(element) ||
  //   element.type === "rectangle" // CHANGED:ADD 2023/10/24 #1179
  // ) {
  //   const viewTransformations = {
  //     zoom: appState.zoom,
  //     offsetLeft: appState.offsetLeft,
  //     offsetTop: appState.offsetTop,
  //     scrollX: appState.scrollX,
  //     scrollY: appState.scrollY,
  //   };
  //   const { x: LeftSceneCoords, y: TopSceneCoords } =
  //     viewportCoordsToSceneCoords(
  //       {
  //         clientX: viewTransformations.offsetLeft,
  //         clientY: viewTransformations.offsetTop,
  //       },
  //       viewTransformations,
  //     );
  //   const { x: RightSceneCoords, y: BottomSceneCoords } =
  //     viewportCoordsToSceneCoords(
  //       {
  //         clientX: viewTransformations.offsetLeft + appState.width,
  //         clientY: viewTransformations.offsetTop + appState.height,
  //       },
  //       viewTransformations,
  //     );

  //   if (LeftSceneCoords > _x1) {
  //     x1 = LeftSceneCoords;
  //   }
  //   if (_x2 > RightSceneCoords) {
  //     x2 = RightSceneCoords;
  //   }
  //   if (TopSceneCoords > _y1) {
  //     y1 = TopSceneCoords;
  //   }
  //   if (_y2 > BottomSceneCoords) {
  //     y2 = BottomSceneCoords;
  //   }
  // }
  // Free draw elements will otherwise "shuffle" as the min x and y change
  if (isFreeDrawElement(element)) {
    x1 = Math.floor(x1);
    x2 = Math.ceil(x2);
    y1 = Math.floor(y1);
    y2 = Math.ceil(y2);
  }

  // CHANGED:ADD 2022-11-18 #175
  else if (isJobElement(element)) {
    const width = x2 - x1;
    x1 = viewportCoordsToSceneCoords(
      {
        clientX: appState.offsetLeft,
        clientY: appState.offsetTop,
      },
      {
        zoom: appState.zoom,
        offsetLeft: appState.offsetLeft,
        offsetTop: appState.offsetTop,
        scrollX: appState.scrollX,
        scrollY: appState.scrollY,
      },
    ).x;
    x2 = x1 + width;
  }

  //CHANGED: ADD 2023-01-28 #391
  else if (isJobTextElement(element)) {
    const width = x2 - (x1 + element.x);
    x1 = viewportCoordsToSceneCoords(
      {
        clientX: appState.offsetLeft,
        clientY: appState.offsetTop,
      },
      {
        zoom: appState.zoom,
        offsetLeft: appState.offsetLeft,
        offsetTop: appState.offsetTop,
        scrollX: appState.scrollX,
        scrollY: appState.scrollY,
      },
    ).x + element.x;
    x2 = x1 + width;
  }

  const cx = ((x1 + x2) / 2 + appState.scrollX) * window.devicePixelRatio;
  const cy = ((y1 + y2) / 2 + appState.scrollY) * window.devicePixelRatio;

  context.save();
  context.scale(1 / window.devicePixelRatio, 1 / window.devicePixelRatio);
  const boundTextElement = getBoundTextElement(element, allElementsMap);

  if (isArrowElement(element) && boundTextElement) {
    const tempCanvas = document.createElement("canvas");
    const tempCanvasContext = tempCanvas.getContext("2d")!;

    // Take max dimensions of arrow canvas so that when canvas is rotated
    // the arrow doesn't get clipped
    const maxDim = Math.max(distance(x1, x2), distance(y1, y2));
    tempCanvas.width =
      maxDim * window.devicePixelRatio * zoom +
      padding * elementWithCanvas.scale * 10;
    tempCanvas.height =
      maxDim * window.devicePixelRatio * zoom +
      padding * elementWithCanvas.scale * 10;
    const offsetX = (tempCanvas.width - elementWithCanvas.canvas!.width) / 2;
    const offsetY = (tempCanvas.height - elementWithCanvas.canvas!.height) / 2;

    tempCanvasContext.translate(tempCanvas.width / 2, tempCanvas.height / 2);
    tempCanvasContext.rotate((element.angle || 0));

    tempCanvasContext.drawImage(
      elementWithCanvas.canvas!,
      -elementWithCanvas.canvas.width / 2,
      -elementWithCanvas.canvas.height / 2,
      elementWithCanvas.canvas.width,
      elementWithCanvas.canvas.height,
    );

    const [, , , , boundTextCx, boundTextCy] = getElementAbsoluteCoords(
      boundTextElement,
      allElementsMap,
    );

    tempCanvasContext.rotate(-(element.angle || 0));

    // Shift the canvas to the center of the bound text element
    const shiftX =
      tempCanvas.width / 2 -
      (boundTextCx - x1) * window.devicePixelRatio * zoom -
      offsetX -
      padding * zoom;

    const shiftY =
      tempCanvas.height / 2 -
      (boundTextCy - y1) * window.devicePixelRatio * zoom -
      offsetY -
      padding * zoom;
    tempCanvasContext.translate(-shiftX, -shiftY);

    // Clear the bound text area
    tempCanvasContext.clearRect(
      -(boundTextElement.width / 2 + BOUND_TEXT_PADDING) *
        window.devicePixelRatio *
        zoom,
      -(boundTextElement.height / 2 + BOUND_TEXT_PADDING) *
        window.devicePixelRatio *
        zoom,
      (boundTextElement.width + BOUND_TEXT_PADDING * 2) *
        window.devicePixelRatio *
        zoom,
      (boundTextElement.height + BOUND_TEXT_PADDING * 2) *
        window.devicePixelRatio *
        zoom,
    );

    // CHANGED:ADD 2023-02-28 #739
    if (element.isClosed) {
      context.globalAlpha = ELEMENT_CLOSED_OPACITY;
    }

    context.translate(cx, cy);
    context.drawImage(
      tempCanvas,
      (-(x2 - x1) / 2) * window.devicePixelRatio - offsetX / zoom - padding,
      (-(y2 - y1) / 2) * window.devicePixelRatio - offsetY / zoom - padding,
      tempCanvas.width / zoom,
      tempCanvas.height / zoom,
    );
    // CHANGED:UPDATE 2023/08/29 #964
  // } else if (isGraphElement(element) && boundTextElement) {
  // CHANGED:REMOVE 2024-01-24 #1526
  //   isGraphElement(element) ||
  //   element.type === "rectangle" // CHANGED:ADD 2023/10/24 #1179
  // ) {
  //   const tempCanvas = document.createElement("canvas");
  //   const tempCanvasContext = tempCanvas.getContext("2d")!;

  //   // Take max dimensions of arrow canvas so that when canvas is rotated
  //   // the arrow doesn't get clipped
  //   // CHANGED:ADD 2023-03-28 #708-2
  //   const elementWithCanvasWidth =
  //     distance(x1, x2) * window.devicePixelRatio * zoom +
  //     padding * appState.zoom.value * 2;
  //   const elementWithCanvasHeight =
  //     distance(y1, y2) * window.devicePixelRatio * zoom +
  //     padding * appState.zoom.value * 2;
  //   const maxDim = Math.max(distance(x1, x2), distance(y1, y2));
  //   tempCanvas.width =
  //     maxDim * window.devicePixelRatio * zoom +
  //     padding * elementWithCanvas.scale * 10;
  //   tempCanvas.height =
  //     maxDim * window.devicePixelRatio * zoom +
  //     padding * elementWithCanvas.scale * 10;
  //   const offsetX = (tempCanvas.width - elementWithCanvasWidth) / 2; // CHANGED:UPDATE 2023-03-28 #708-2 > #1154
  //   const offsetY = (tempCanvas.height - elementWithCanvasHeight) / 2; // CHANGED:UPDATE 2023-03-28 #708-2 > #1154

  //   tempCanvasContext.translate(tempCanvas.width / 2, tempCanvas.height / 2);
  //   tempCanvasContext.rotate((element.angle || 0));

  //   // CHANGED:UPDATE 2023-03-28 #708-2
  //   tempCanvasContext.drawImage(
  //     elementWithCanvas.canvas!,
  //     -(_x1 - x1) * window.devicePixelRatio * zoom, //CHANGED:UPDATE 2023/10/16 #1154
  //     -(_y1 - y1) * window.devicePixelRatio * zoom, //CHANGED:UPDATE 2023/10/16 #1154
  //     elementWithCanvasWidth,
  //     elementWithCanvasHeight,
  //     -elementWithCanvasWidth / 2,
  //     -elementWithCanvasHeight / 2,
  //     elementWithCanvasWidth,
  //     elementWithCanvasHeight,
  //   );

  //   // CHANGED:REMOVE 2023-03-31 #804
  //   // const [, , , , boundTextCx, boundTextCy] =
  //   //   getElementAbsoluteCoords(boundTextElement);

  //   // tempCanvasContext.rotate(-(element.angle || 0));

  //   // // Shift the canvas to the center of the bound text element
  //   // const shiftX =
  //   //   tempCanvas.width / 2 -
  //   //   (boundTextCx - x1) * window.devicePixelRatio * zoom -
  //   //   offsetX -
  //   //   padding * zoom;

  //   // const shiftY =
  //   //   tempCanvas.height / 2 -
  //   //   (boundTextCy - y1) * window.devicePixelRatio * zoom -
  //   //   offsetY -
  //   //   padding * zoom;
  //   // tempCanvasContext.translate(-shiftX, -shiftY);

  //   // // Clear the bound text area
  //   // tempCanvasContext.clearRect(
  //   //   -(boundTextElement.width / 2 + BOUND_TEXT_PADDING) *
  //   //     window.devicePixelRatio *
  //   //     zoom,
  //   //   -(boundTextElement.height / 2 + BOUND_TEXT_PADDING) *
  //   //     window.devicePixelRatio *
  //   //     zoom,
  //   //   (boundTextElement.width + BOUND_TEXT_PADDING * 2) *
  //   //     window.devicePixelRatio *
  //   //     zoom,
  //   //   (boundTextElement.height + BOUND_TEXT_PADDING * 2) *
  //   //     window.devicePixelRatio *
  //   //     zoom,
  //   // );

  //   // CHANGED:ADD 2023-2-27 #743 // CHANGED:ADD 2023-3-10 #763
  //   if (appState.emphasizedModeEnabled || appState.transparentModeEnabled) {
  //     if (appState.selectedTaskElement) {
  //       if (appState.selectedTaskElement.dependencyElementIds[element.id]) {
  //         if (appState.emphasizedModeEnabled) {
  //           context.shadowColor = ColorsEx.lineColor.shadow;
  //           context.shadowBlur = DEPENDENCY_ELEMENT_SHADOW_BLUR;
  //           context.shadowOffsetX = DEPENDENCY_ELEMENT_SHADOW_OFFSET_X;
  //           context.shadowOffsetY = DEPENDENCY_ELEMENT_SHADOW_OFFSET_Y;
  //         }
  //       } else if (
  //         appState.transparentModeEnabled &&
  //         appState.selectedTaskElement.elementId !== element.id
  //       ) {
  //         context.globalAlpha = NON_DEPENDENCY_ELEMENT_OPACITY;
  //       }
  //     }
  //   }

  //   // CHANGED:ADD 2023-02-28 #739
  //   if (element.isClosed) {
  //     context.globalAlpha = ELEMENT_CLOSED_OPACITY;
  //   }

  //   context.translate(cx, cy);
  //   context.drawImage(
  //     tempCanvas,
  //     (-(x2 - x1) / 2) * window.devicePixelRatio - offsetX / zoom - padding,
  //     (-(y2 - y1) / 2) * window.devicePixelRatio - offsetY / zoom - padding,
  //     tempCanvas.width / zoom,
  //     tempCanvas.height / zoom,
  //   );
  //   // CHANGED:ADD 2023-03-29 #790
  } else if (
    isJobElement(element) && !element.locked) 
  {
    const tempCanvas = document.createElement("canvas");
    const tempCanvasContext = tempCanvas.getContext("2d")!;

    // Take max dimensions of arrow canvas so that when canvas is rotated
    // the arrow doesn't get clipped
    const maxDim = Math.max(distance(x1, x2), distance(y1, y2));
    tempCanvas.width =
      maxDim * window.devicePixelRatio * zoom +
      padding * elementWithCanvas.scale * 10;
    tempCanvas.height =
      maxDim * window.devicePixelRatio * zoom +
      padding * elementWithCanvas.scale * 10;
    const offsetX = (tempCanvas.width - elementWithCanvas.canvas!.width) / 2;
    const offsetY = (tempCanvas.height - elementWithCanvas.canvas!.height) / 2;

    tempCanvasContext.translate(tempCanvas.width / 2, tempCanvas.height / 2);
    tempCanvasContext.rotate((element.angle || 0));

    const zoomRate = window.devicePixelRatio * zoom;
    const x = (element.width / 2) * zoomRate;
    const y = (element.height / 2) * zoomRate;
    const lineX = x - 30 * zoomRate;
    const lineY = 0;
    const lineWidth = (JOB_ACCORDION_BUTTON_SIZE / 2) * zoomRate;
    const lineHeight = (JOB_ACCORDION_BUTTON_SIZE / 3 * 2) * zoomRate;
    const cornerRadius = JOB_ACCORDION_BUTTON_ROUND * zoomRate;

    tempCanvasContext.drawImage(
      elementWithCanvas.canvas!,
      -elementWithCanvas.canvas.width / 2,
      -elementWithCanvas.canvas.height / 2,
      elementWithCanvas.canvas.width,
      elementWithCanvas.canvas.height,
    );

    tempCanvasContext.lineWidth = 1;

    const drawRoundedTriangle = (
      x: number,
      y: number,
      width: number,
      height: number,
      cornerRadius: number
    ) => {
      tempCanvasContext.beginPath();
      tempCanvasContext.moveTo(x, y);
      tempCanvasContext.lineTo(
        x + width,
        y + height
      );
      tempCanvasContext.lineTo(x + width * 2, y);
      tempCanvasContext.lineTo(x, y);
      tempCanvasContext.closePath();
      tempCanvasContext.fill();

      // stroke the triangle path.

      tempCanvasContext.lineWidth = cornerRadius;
      tempCanvasContext.lineJoin = "round";
      tempCanvasContext.stroke();
    }

    drawRoundedTriangle(
      lineX,
      lineY,
      lineWidth,
      element.isCompressed ? lineHeight : - lineHeight,
      cornerRadius,
    );

    context.translate(cx, cy);
    context.drawImage(
      tempCanvas,
      (-(x2 - x1) / 2) * window.devicePixelRatio - offsetX / zoom - padding,
      (-(y2 - y1) / 2) * window.devicePixelRatio - offsetY / zoom - padding,
      tempCanvas.width / zoom,
      tempCanvas.height / zoom,
    );
  } else {
    // we translate context to element center so that rotation and scale
    // originates from the element center
    context.translate(cx, cy);

    context.rotate((element.angle || 0));

    if (
      "scale" in elementWithCanvas.element &&
      !isPendingImageElement(element, renderConfig)
    ) {
      context.scale(
        elementWithCanvas.element.scale[0],
        elementWithCanvas.element.scale[1],
      );
    }

    // revert afterwards we don't have account for it during drawing
    context.translate(-cx, -cy);

    // CHANGED:ADD 2023-2-27 #743 // CHANGED:ADD 2023-3-10 #763
    if (appState.emphasizedModeEnabled || appState.transparentModeEnabled) {
      if (
        (appState.selectedTaskElement) ||
        (appState.selectedLinkElement)
      ) {
        const elementId = isGraphElement(element)
          ? element.id
          : isTextElement(element) &&
            element.containerId &&
            isGraphElement(getContainerElement(element, allElementsMap))
            ? element.containerId
            : null

        if (elementId) {
          if (
            appState.selectedTaskElement?.dependencyElementIds[elementId] ||
            appState.selectedLinkElement?.dependencyElementIds[elementId]
          ) {
            if (appState.emphasizedModeEnabled) {
              context.shadowColor = ColorsEx.lineColor.shadow;
              context.shadowBlur = DEPENDENCY_ELEMENT_SHADOW_BLUR;
              context.shadowOffsetX = DEPENDENCY_ELEMENT_SHADOW_OFFSET_X;
              context.shadowOffsetY = DEPENDENCY_ELEMENT_SHADOW_OFFSET_Y;
            }
          } else if (
            appState.transparentModeEnabled &&
            appState.selectedTaskElement?.elementId !== elementId &&
            appState.selectedLinkElement?.elementId !== elementId &&
            !renderConfig.isExporting
          ) {
            context.globalAlpha = NON_DEPENDENCY_ELEMENT_OPACITY;
          }
        }
      }

      // Emphasizing comment element when selected.
      if (isCommentElement(element) && appState.selectedElementIds[element.id]) {
        context.shadowColor = ColorsEx.lineColor.shadow;
        context.shadowBlur = DEPENDENCY_ELEMENT_SHADOW_BLUR;
        context.shadowOffsetX = DEPENDENCY_ELEMENT_SHADOW_OFFSET_X;
        context.shadowOffsetY = DEPENDENCY_ELEMENT_SHADOW_OFFSET_Y;
      }
    }

    // CHANGED:ADD 2023-02-28 #739
    if (element.isClosed) {
      context.globalAlpha = ELEMENT_CLOSED_OPACITY;
    }
    context.drawImage(
      elementWithCanvas.canvas!,
      (x1 + appState.scrollX) * window.devicePixelRatio -
        (padding * elementWithCanvas.scale) / elementWithCanvas.scale,
      (y1 + appState.scrollY) * window.devicePixelRatio -
        (padding * elementWithCanvas.scale) / elementWithCanvas.scale,
      elementWithCanvas.canvas!.width / elementWithCanvas.scale,
      elementWithCanvas.canvas!.height / elementWithCanvas.scale,
    );

    if (
      import.meta.env.VITE_APP_DEBUG_ENABLE_TEXT_CONTAINER_BOUNDING_BOX &&
      hasBoundTextElement(element)
    ) {
      const textElement = getBoundTextElement(
        element,
        allElementsMap,
      ) as ExcalidrawTextElementWithContainer;
      const coords = getContainerCoords(element);
      context.strokeStyle = "#c92a2a";
      context.lineWidth = 3;
      context.strokeRect(
        (coords.x + appState.scrollX) * window.devicePixelRatio,
        (coords.y + appState.scrollY) * window.devicePixelRatio,
        getBoundTextMaxWidth(element, textElement) * window.devicePixelRatio,
        getBoundTextMaxHeight(element, textElement) * window.devicePixelRatio,
      );
    }
  }
  context.restore();

  // Clear the nested element we appended to the DOM
};

export const renderSelectionElement = (
  element: NonDeletedExcalidrawElement,
  context: CanvasRenderingContext2D,
  appState: InteractiveCanvasAppState,
) => {
  context.save();
  context.translate(element.x + appState.scrollX, element.y + appState.scrollY);
  context.fillStyle = "rgba(0, 0, 200, 0.04)";

  // render from 0.5px offset  to get 1px wide line
  // https://stackoverflow.com/questions/7530593/html5-canvas-and-line-width/7531540#7531540
  // TODO can be be improved by offseting to the negative when user selects
  // from right to left
  const offset = 0.5 / appState.zoom.value;

  context.fillRect(offset, offset, element.width, element.height);
  context.lineWidth = 1 / appState.zoom.value;
  context.strokeStyle = " rgb(105, 101, 219)";
  context.strokeRect(offset, offset, element.width, element.height);

  context.restore();
};

export const renderElement = (
  element: NonDeletedExcalidrawElement,
  elementsMap: RenderableElementsMap,
  allElementsMap: NonDeletedSceneElementsMap,
  rc: RoughCanvas,
  context: CanvasRenderingContext2D,
  renderConfig: StaticCanvasRenderConfig,
  appState: StaticCanvasAppState,
) => {
  const generator = rc.generator;
  switch (element.type) {
    case "freedraw": {
      ShapeCache.generateElementShape(element, null);

      // if (renderConfig.isExporting) {
      //   const [x1, y1, x2, y2] = getElementAbsoluteCoords(element);
      //   const cx = (x1 + x2) / 2 + appState.scrollX;
      //   const cy = (y1 + y2) / 2 + appState.scrollY;
      //   const shiftX = (x2 - x1) / 2 - (element.x - x1);
      //   const shiftY = (y2 - y1) / 2 - (element.y - y1);
      //   context.save();
      //   context.translate(cx, cy);
      //   context.rotate((element.angle || 0));
      //   context.translate(-shiftX, -shiftY);
      //   drawElementOnCanvas(
      //     element,
      //     elementsMap,
      //     rc,
      //     context,
      //     renderConfig,
      //     appState
      //   );
      //   context.restore();
      // } else {
        const elementWithCanvas = generateElementWithCanvas(
          element,
          elementsMap,
          renderConfig,
          appState,
        );
        drawElementFromCanvas(
          elementWithCanvas,
          context,
          renderConfig,
          appState,
          allElementsMap,
        );
      // }

      break;
    }
    case "comment": // CHANGED:ADD 2023-12-20 #1138
    case "job": // CHANGED:ADD 2022-11-18 #175
    case "task": // CHANGED:ADD 2022-10-28 #14
    case "milestone": // CHANGED:ADD 2022-12-7 #157
    case "link": // CHANGED:ADD 2022-11-2 #64
    case "rectangle":
    case "diamond":
    case "ellipse":
    case "line":
    case "arrow":
    case "image":
    case "job-text": // CHANGED:ADD 2023-01-28 #391
    case "text": {
      ShapeCache.generateElementShape(element, renderConfig);
      // if (renderConfig.isExporting) {
      //   const [x1, y1, x2, y2] = getElementAbsoluteCoords(element);
      //   const cx = (x1 + x2) / 2 + appState.scrollX;
      //   const cy = (y1 + y2) / 2 + appState.scrollY;
      //   let shiftX = (x2 - x1) / 2 - (element.x - x1);
      //   let shiftY = (y2 - y1) / 2 - (element.y - y1);
      //   if (isTextElement(element)) {
      //     const container = getContainerElement(element, elementsMap);
      //     if (isArrowElement(container)) {
      //       const boundTextCoords =
      //         LinearElementEditor.getBoundTextElementPosition(
      //           container,
      //           element as ExcalidrawTextElementWithContainer,
      //         );
      //       shiftX = (x2 - x1) / 2 - (boundTextCoords.x - x1);
      //       shiftY = (y2 - y1) / 2 - (boundTextCoords.y - y1);
      //     } else if (isTaskElement(container)) { //CHANGED:ADD 2022/12/08 #225
      //       const boundTextCoords =
      //         TaskElementEditor.getBoundTextElementPosition(
      //           container,
      //           element as ExcalidrawTextElementWithContainer,
      //         );
      //       shiftX = (x2 - x1) / 2 - (boundTextCoords.x - x1);
      //       shiftY = (y2 - y1) / 2 - (boundTextCoords.y - y1);
      //     } else if (isMilestoneElement(container)) { // CHANGED:ADD 2022-12-13 #289
      //       const boundTextCoords =
      //         MilestoneElementEditor.getBoundTextElementPosition(
      //           container,
      //           element as ExcalidrawTextElementWithContainer,
      //         );
      //       shiftX = (x2 - x1) / 2 - (boundTextCoords.x - x1);
      //       shiftY = (y2 - y1) / 2 - (boundTextCoords.y - y1);
      //     } else if (isLinkElement(container)) { //CHANGED:ADD 2023/01/17 #390
      //       const boundTextCoords =
      //         LinkElementEditor.getBoundTextElementPosition(
      //           container,
      //           element as ExcalidrawTextElementWithContainer,
      //         );
      //       shiftX = (x2 - x1) / 2 - (boundTextCoords.x - x1);
      //       shiftY = (y2 - y1) / 2 - (boundTextCoords.y - y1);
      //     }
      //   }
      //   context.save();
      //   context.translate(cx, cy);

      //   if (shouldResetImageFilter(element, renderConfig, appState)) {
      //     context.filter = "none";
      //   }
      //   const boundTextElement = getBoundTextElement(element, elementsMap);
      //   //CHANGED:UPDATE 2023/01/17 #390
      //   if ((isArrowElement(element) || isGraphElement(element)) && boundTextElement) {
      //     const tempCanvas = document.createElement("canvas");

      //     const tempCanvasContext = tempCanvas.getContext("2d")!;

      //     // Take max dimensions of arrow canvas so that when canvas is rotated
      //     // the arrow doesn't get clipped
      //     const maxDim = Math.max(distance(x1, x2), distance(y1, y2));
      //     const padding = getCanvasPadding(element);
      //     tempCanvas.width =
      //       maxDim * appState.exportScale + padding * 10 * appState.exportScale;
      //     tempCanvas.height =
      //       maxDim * appState.exportScale + padding * 10 * appState.exportScale;

      //     tempCanvasContext.translate(
      //       tempCanvas.width / 2,
      //       tempCanvas.height / 2,
      //     );
      //     tempCanvasContext.scale(appState.exportScale, appState.exportScale);

      //     // Shift the canvas to left most point of the arrow
      //     shiftX = element.width / 2 - (element.x - x1);
      //     shiftY = element.height / 2 - (element.y - y1);

      //     tempCanvasContext.rotate((element.angle || 0));
      //     const tempRc = rough.canvas(tempCanvas);

      //     tempCanvasContext.translate(-shiftX, -shiftY);

      //     drawElementOnCanvas(
      //       element,
      //       elementsMap,
      //       tempRc,
      //       tempCanvasContext,
      //       renderConfig,
      //       appState,
      //     );

      //     tempCanvasContext.translate(shiftX, shiftY);

      //     tempCanvasContext.rotate(-(element.angle || 0));

      //     // Shift the canvas to center of bound text
      //     const [, , , , boundTextCx, boundTextCy] =
      //       getElementAbsoluteCoords(boundTextElement);
      //     const boundTextShiftX = (x1 + x2) / 2 - boundTextCx;
      //     const boundTextShiftY = (y1 + y2) / 2 - boundTextCy;
      //     tempCanvasContext.translate(-boundTextShiftX, -boundTextShiftY);

      //     // Clear the bound text area
      //     tempCanvasContext.clearRect(
      //       -boundTextElement.width / 2,
      //       -boundTextElement.height / 2,
      //       boundTextElement.width,
      //       boundTextElement.height,
      //     );
      //     context.scale(1 / appState.exportScale, 1 / appState.exportScale);
      //     context.drawImage(
      //       tempCanvas,
      //       -tempCanvas.width / 2,
      //       -tempCanvas.height / 2,
      //       tempCanvas.width,
      //       tempCanvas.height,
      //     );
      //   } else {
      //     context.rotate((element.angle || 0));

      //     if (element.type === "image") {
      //       // note: scale must be applied *after* rotating
      //       context.scale(element.scale[0], element.scale[1]);
      //     }

      //     context.translate(-shiftX, -shiftY);
      //     drawElementOnCanvas(
      //       element,
      //       elementsMap,
      //       rc,
      //       context,
      //       renderConfig,
      //       appState
      //     );
      //   }

      //   context.restore();
      //   // not exporting → optimized rendering (cache & render from element
      //   // canvases)
      // } else {
        const elementWithCanvas = generateElementWithCanvas(
          element,
          elementsMap,
          renderConfig,
          appState,
        );

        const currentImageSmoothingStatus = context.imageSmoothingEnabled;

        if (
          // do not disable smoothing during zoom as blurry shapes look better
          // on low resolution (while still zooming in) than sharp ones
          !appState?.shouldCacheIgnoreZoom &&
          // angle is 0 -> always disable smoothing
          (!(element.angle || 0) ||
            // or check if angle is a right angle in which case we can still
            // disable smoothing without adversely affecting the result
            isRightAngle((element.angle || 0)))
        ) {
          // Disabling smoothing makes output much sharper, especially for
          // text. Unless for non-right angles, where the aliasing is really
          // terrible on Chromium.
          //
          // Note that `context.imageSmoothingQuality="high"` has almost
          // zero effect.
          //
          context.imageSmoothingEnabled = false;
        }

        drawElementFromCanvas(
          elementWithCanvas,
          context,
          renderConfig,
          appState,
          allElementsMap,
        );

        // reset
        context.imageSmoothingEnabled = currentImageSmoothingStatus;
      // }
      break;
    }
    default: {
      // @ts-ignore
      throw new Error(`Unimplemented type ${element.type}`);
    }
  }
};

const roughSVGDrawWithPrecision = (
  rsvg: RoughSVG,
  drawable: Drawable,
  precision?: number,
) => {
  if (typeof precision === "undefined") {
    return rsvg.draw(drawable);
  }
  const pshape: Drawable = {
    sets: drawable.sets,
    shape: drawable.shape,
    options: { ...drawable.options, fixedDecimalPlaceDigits: precision },
  };
  return rsvg.draw(pshape);
};

export const renderElementToSvg = (
  element: NonDeletedExcalidrawElement,
  elementsMap: RenderableElementsMap,
  rsvg: RoughSVG,
  svgRoot: SVGElement,
  files: BinaryFiles,
  offsetX: number,
  offsetY: number,
  renderConfig: SVGRenderConfig,
) => {
  const offset = { x: offsetX, y: offsetY };
  const [x1, y1, x2, y2] = getElementAbsoluteCoords(element, elementsMap);
  let cx = (x2 - x1) / 2 - (element.x - x1);
  let cy = (y2 - y1) / 2 - (element.y - y1);
  if (isTextElement(element)) {
    const container = getContainerElement(element, elementsMap);
    if (isArrowElement(container)) {
      const [x1, y1, x2, y2] = getElementAbsoluteCoords(container, elementsMap);

      const boundTextCoords = LinearElementEditor.getBoundTextElementPosition(
        container,
        element as ExcalidrawTextElementWithContainer,
        elementsMap,
      );
      cx = (x2 - x1) / 2 - (boundTextCoords.x - x1);
      cy = (y2 - y1) / 2 - (boundTextCoords.y - y1);
      offsetX = offsetX + boundTextCoords.x - element.x;
      offsetY = offsetY + boundTextCoords.y - element.y;
    } else if (isTaskElement(container)) { //CHANGED:ADD 2022/12/08 #225
      const [x1, y1, x2, y2] = getElementAbsoluteCoords(container, elementsMap);

      const boundTextCoords = TaskElementEditor.getBoundTextElementPosition(
        container,
        element as ExcalidrawTextElementWithContainer,
        elementsMap,
      );
      cx = (x2 - x1) / 2 - (boundTextCoords.x - x1);
      cy = (y2 - y1) / 2 - (boundTextCoords.y - y1);
      offsetX = offsetX + boundTextCoords.x - element.x;
      offsetY = offsetY + boundTextCoords.y - element.y;
    } else if (isMilestoneElement(container)) { // CHANGED:ADD 2022-12-13 #289
      const [x1, y1, x2, y2] = getElementAbsoluteCoords(container, elementsMap);

      const boundTextCoords = MilestoneElementEditor.getBoundTextElementPosition(
        container,
        element as ExcalidrawTextElementWithContainer,
        elementsMap,
      );
      cx = (x2 - x1) / 2 - (boundTextCoords.x - x1);
      cy = (y2 - y1) / 2 - (boundTextCoords.y - y1);
      offsetX = offsetX + boundTextCoords.x - element.x;
      offsetY = offsetY + boundTextCoords.y - element.y;
    } else if (isLinkElement(container)) { //CHANGED:ADD 2023/01/17 #390
      const [x1, y1, x2, y2] = getElementAbsoluteCoords(container, elementsMap);

      const boundTextCoords = LinkElementEditor.getBoundTextElementPosition(
        container,
        element as ExcalidrawTextElementWithContainer,
        elementsMap,
      );
      cx = (x2 - x1) / 2 - (boundTextCoords.x - x1);
      cy = (y2 - y1) / 2 - (boundTextCoords.y - y1);
      offsetX = offsetX + boundTextCoords.x - element.x;
      offsetY = offsetY + boundTextCoords.y - element.y;
    }
  }
  const degree = (180 * (element.angle || 0)) / Math.PI;
  const generator = rsvg.generator;

  // element to append node to, most of the time svgRoot
  let root = svgRoot;

  // if the element has a link, create an anchor tag and make that the new root
  if (element.link) {
    const anchorTag = svgRoot.ownerDocument!.createElementNS(SVG_NS, "a");
    anchorTag.setAttribute("href", element.link);
    root.appendChild(anchorTag);
    root = anchorTag;
  }

  switch (element.type) {
    case "selection": {
      // Since this is used only during editing experience, which is canvas based,
      // this should not happen
      throw new Error("Selection rendering is not supported for SVG");
    }
    case "job": // CHANGED:ADD 2023-02-28 #59
    case "milestone": // CHANGED:ADD 2022-12-7 #157
    case "rectangle":
    case "diamond":
    case "ellipse": {
      const shape = ShapeCache.generateElementShape(element, null);
      const node = roughSVGDrawWithPrecision(
        rsvg,
        shape,
        MAX_DECIMALS_FOR_SVG_EXPORT,
      );
      const opacity = (element.opacity || 100) / 100;
      if (opacity !== 1) {
        node.setAttribute("stroke-opacity", `${opacity}`);
        node.setAttribute("fill-opacity", `${opacity}`);
      }
      node.setAttribute("strokeLinecap", "round");
      node.setAttribute(
        "transform",
        `translate(${offsetX || 0} ${
          offsetY || 0
        }) rotate(${degree} ${cx} ${cy})`,
      );
      root.appendChild(node);
      break;
    }
    case "line":
    case "arrow": {
      const boundText = getBoundTextElement(element, elementsMap);
      const maskPath = svgRoot.ownerDocument!.createElementNS(SVG_NS, "mask");
      if (boundText) {
        maskPath.setAttribute("id", `mask-${element.id}`);
        const maskRectVisible = svgRoot.ownerDocument!.createElementNS(
          SVG_NS,
          "rect",
        );
        offsetX = offsetX || 0;
        offsetY = offsetY || 0;
        maskRectVisible.setAttribute("x", "0");
        maskRectVisible.setAttribute("y", "0");
        maskRectVisible.setAttribute("fill", "#fff");
        maskRectVisible.setAttribute(
          "width",
          `${element.width + 100 + offsetX}`,
        );
        maskRectVisible.setAttribute(
          "height",
          `${element.height + 100 + offsetY}`,
        );

        maskPath.appendChild(maskRectVisible);
        const maskRectInvisible = svgRoot.ownerDocument!.createElementNS(
          SVG_NS,
          "rect",
        );
        const boundTextCoords = LinearElementEditor.getBoundTextElementPosition(
          element,
          boundText,
          elementsMap,
        );

        const maskX = offsetX + boundTextCoords.x - element.x;
        const maskY = offsetY + boundTextCoords.y - element.y;

        maskRectInvisible.setAttribute("x", maskX.toString());
        maskRectInvisible.setAttribute("y", maskY.toString());
        maskRectInvisible.setAttribute("fill", "#000");
        maskRectInvisible.setAttribute("width", `${boundText.width}`);
        maskRectInvisible.setAttribute("height", `${boundText.height}`);
        maskRectInvisible.setAttribute("opacity", "1");
        maskPath.appendChild(maskRectInvisible);
      }
      const shapes = ShapeCache.generateElementShape(element, renderConfig);
      const group = svgRoot.ownerDocument!.createElementNS(SVG_NS, "g");
      if (boundText) {
        group.setAttribute("mask", `url(#mask-${element.id})`);
      }
      const opacity = (element.opacity || 100) / 100;
      group.setAttribute("strokeLinecap", "round");

      shapes.forEach((shape) => {
        const node = roughSVGDrawWithPrecision(
          rsvg,
          shape,
          MAX_DECIMALS_FOR_SVG_EXPORT,
        );
        if (opacity !== 1) {
          node.setAttribute("stroke-opacity", `${opacity}`);
          node.setAttribute("fill-opacity", `${opacity}`);
        }
        node.setAttribute(
          "transform",
          `translate(${offsetX || 0} ${
            offsetY || 0
          }) rotate(${degree} ${cx} ${cy})`,
        );
        if (
          element.type === "line" &&
          isPathALoop(element.points) &&
          element.backgroundColor !== "transparent"
        ) {
          node.setAttribute("fill-rule", "evenodd");
        }
        group.appendChild(node);
      });
      root.appendChild(group);
      root.append(maskPath);
      break;
    }
    case "task": { // CHANGED:ADD 2022-10-28 #14
      const boundText = getBoundTextElement(element, elementsMap);
      const maskPath = svgRoot.ownerDocument!.createElementNS(SVG_NS, "mask");
      if (boundText) {
        maskPath.setAttribute("id", `mask-${element.id}`);
        const maskRectVisible = svgRoot.ownerDocument!.createElementNS(
          SVG_NS,
          "rect",
        );
        offsetX = offsetX || 0;
        offsetY = offsetY || 0;
        maskRectVisible.setAttribute("x", "0");
        maskRectVisible.setAttribute("y", "0");
        maskRectVisible.setAttribute("fill", "#fff");
        maskRectVisible.setAttribute(
          "width",
          `${element.width + 100 + offsetX}`,
        );
        maskRectVisible.setAttribute(
          "height",
          `${element.height + 100 + offsetY}`,
        );

        maskPath.appendChild(maskRectVisible);
        const maskRectInvisible = svgRoot.ownerDocument!.createElementNS(
          SVG_NS,
          "rect",
        );
        const boundTextCoords = TaskElementEditor.getBoundTextElementPosition(
          element,
          boundText,
          elementsMap,
        );

        const maskX = offsetX + boundTextCoords.x - element.x;
        const maskY = offsetY + boundTextCoords.y - element.y;

        maskRectInvisible.setAttribute("x", maskX.toString());
        maskRectInvisible.setAttribute("y", maskY.toString());
        maskRectInvisible.setAttribute("fill", "#000");
        maskRectInvisible.setAttribute("width", `${boundText.width}`);
        maskRectInvisible.setAttribute("height", `${boundText.height}`);
        maskRectInvisible.setAttribute("opacity", "1");
        maskPath.appendChild(maskRectInvisible);
      }
      const shapes = ShapeCache.generateElementShape(element, renderConfig);
      const group = svgRoot.ownerDocument!.createElementNS(SVG_NS, "g");
      if (boundText) {
        group.setAttribute("mask", `url(#mask-${element.id})`);
      }
      const opacity = (element.opacity || 100) / 100;
      group.setAttribute("strokeLinecap", "round");

      shapes.forEach((shape) => {
        const node = roughSVGDrawWithPrecision(
          rsvg,
          shape,
          MAX_DECIMALS_FOR_SVG_EXPORT,
        );
        if (opacity !== 1) {
          node.setAttribute("stroke-opacity", `${opacity}`);
          node.setAttribute("fill-opacity", `${opacity}`);
        }
        node.setAttribute(
          "transform",
          `translate(${offsetX || 0} ${offsetY || 0
          }) rotate(${degree} ${cx} ${cy})`,
        );
        node.setAttribute("fill-rule", "evenodd");
        group.appendChild(node);
      });
      root.appendChild(group);
      root.append(maskPath);
      break;
    }
    case "link": { // CHANGED:ADD 2022-11-2 #64
      const shapes = ShapeCache.generateElementShape(element, renderConfig);
      const group = svgRoot.ownerDocument!.createElementNS(SVG_NS, "g");
      const opacity = (element.opacity || 100) / 100;
      group.setAttribute("strokeLinecap", "round");

      shapes.forEach((shape) => {
        const node = roughSVGDrawWithPrecision(
          rsvg,
          shape,
          MAX_DECIMALS_FOR_SVG_EXPORT,
        );
        if (opacity !== 1) {
          node.setAttribute("stroke-opacity", `${opacity}`);
          node.setAttribute("fill-opacity", `${opacity}`);
        }
        node.setAttribute(
          "transform",
          `translate(${offsetX || 0} ${offsetY || 0
          }) rotate(${degree} ${cx} ${cy})`,
        );
        node.setAttribute("fill-rule", "evenodd");
        group.appendChild(node);
      });
      root.appendChild(group);
      break;
    }
    case "freedraw": {
      ShapeCache.generateElementShape(element, renderConfig);
      generateFreeDrawShape(element);
      const opacity = (element.opacity || 100) / 100;
      const shape = ShapeCache.get(element);
      const node = shape
        ? roughSVGDrawWithPrecision(rsvg, shape, MAX_DECIMALS_FOR_SVG_EXPORT)
        : svgRoot.ownerDocument!.createElementNS(SVG_NS, "g");
      if (opacity !== 1) {
        node.setAttribute("stroke-opacity", `${opacity}`);
        node.setAttribute("fill-opacity", `${opacity}`);
      }
      node.setAttribute(
        "transform",
        `translate(${offsetX || 0} ${
          offsetY || 0
        }) rotate(${degree} ${cx} ${cy})`,
      );
      node.setAttribute("stroke", "none");
      const path = svgRoot.ownerDocument!.createElementNS(SVG_NS, "path");
      path.setAttribute("fill", element.strokeColor);
      path.setAttribute("d", getFreeDrawSvgPath(element));
      node.appendChild(path);
      root.appendChild(node);
      break;
    }
    case "image": {
      const width = Math.round(element.width);
      const height = Math.round(element.height);
      const fileData =
        isInitializedImageElement(element) && files[element.fileId];
      if (fileData) {
        const symbolId = `image-${fileData.id}`;
        let symbol = svgRoot.querySelector(`#${symbolId}`);
        if (!symbol) {
          symbol = svgRoot.ownerDocument!.createElementNS(SVG_NS, "symbol");
          symbol.id = symbolId;

          const image = svgRoot.ownerDocument!.createElementNS(SVG_NS, "image");

          image.setAttribute("width", "100%");
          image.setAttribute("height", "100%");
          image.setAttribute("href", fileData.dataURL);

          symbol.appendChild(image);

          root.prepend(symbol);
        }

        const use = svgRoot.ownerDocument!.createElementNS(SVG_NS, "use");
        use.setAttribute("href", `#${symbolId}`);

        // in dark theme, revert the image color filter
        if (
          renderConfig.exportWithDarkMode &&
          fileData.mimeType !== MIME_TYPES.svg
        ) {
          use.setAttribute("filter", IMAGE_INVERT_FILTER);
        }

        use.setAttribute("width", `${width}`);
        use.setAttribute("height", `${height}`);

        // We first apply `scale` transforms (horizontal/vertical mirroring)
        // on the <use> element, then apply translation and rotation
        // on the <g> element which wraps the <use>.
        // Doing this separately is a quick hack to to work around compositing
        // the transformations correctly (the transform-origin was not being
        // applied correctly).
        if (element.scale[0] !== 1 || element.scale[1] !== 1) {
          const translateX = element.scale[0] !== 1 ? -width : 0;
          const translateY = element.scale[1] !== 1 ? -height : 0;
          use.setAttribute(
            "transform",
            `scale(${element.scale[0]}, ${element.scale[1]}) translate(${translateX} ${translateY})`,
          );
        }

        const g = svgRoot.ownerDocument!.createElementNS(SVG_NS, "g");
        g.appendChild(use);
        g.setAttribute(
          "transform",
          `translate(${offsetX || 0} ${
            offsetY || 0
          }) rotate(${degree} ${cx} ${cy})`,
        );

        root.appendChild(g);
      }
      break;
    }
    default: {
      if (isTextElement(element)) {
        const opacity = (element.opacity || 100) / 100;
        const node = svgRoot.ownerDocument!.createElementNS(SVG_NS, "g");
        if (opacity !== 1) {
          node.setAttribute("stroke-opacity", `${opacity}`);
          node.setAttribute("fill-opacity", `${opacity}`);
        }

        node.setAttribute(
          "transform",
          `translate(${offsetX || 0} ${
            offsetY || 0
          }) rotate(${degree} ${cx} ${cy})`,
        );
        const lines = element.text.replace(/\r\n?/g, "\n").split("\n");
        const lineHeightPx = getLineHeightInPx(
          element.fontSize,
          element.lineHeight,
        );
        const horizontalOffset =
          element.textAlign === "center"
            ? element.width / 2
            : element.textAlign === "right"
            ? element.width
            : 0;
        const direction = isRTL(element.text) ? "rtl" : "ltr";
        const textAnchor =
          element.textAlign === "center"
            ? "middle"
            : element.textAlign === "right" || direction === "rtl"
            ? "end"
            : "start";
        for (let i = 0; i < lines.length; i++) {
          const text = svgRoot.ownerDocument!.createElementNS(SVG_NS, "text");
          text.textContent = lines[i];
          text.setAttribute("x", `${horizontalOffset}`);
          text.setAttribute("y", `${i * lineHeightPx}`);
          text.setAttribute("font-family", getFontFamilyString(element));
          text.setAttribute("font-size", `${element.fontSize}px`);
          text.setAttribute("fill", element.strokeColor);
          text.setAttribute("text-anchor", textAnchor);
          text.setAttribute("style", "white-space: pre;");
          text.setAttribute("direction", direction);
          text.setAttribute("dominant-baseline", "text-before-edge");
          node.appendChild(text);
        }
        root.appendChild(node);
      } else {
        // @ts-ignore
        throw new Error(`Unimplemented type ${element.type}`);
      }
    }
  }
};

export const pathsCache = new WeakMap<ExcalidrawFreeDrawElement, Path2D>([]);

export function generateFreeDrawShape(element: ExcalidrawFreeDrawElement) {
  const svgPathData = getFreeDrawSvgPath(element);
  const path = new Path2D(svgPathData);
  pathsCache.set(element, path);
  return path;
}

export function getFreeDrawPath2D(element: ExcalidrawFreeDrawElement) {
  return pathsCache.get(element);
}

export function getFreeDrawSvgPath(element: ExcalidrawFreeDrawElement) {
  // If input points are empty (should they ever be?) return a dot
  const inputPoints = element.simulatePressure
    ? element.points
    : element.points.length
    ? element.points.map(([x, y], i) => [x, y, element.pressures[i]])
    : [[0, 0, 0.5]];

  // Consider changing the options for simulated pressure vs real pressure
  const options: StrokeOptions = {
    simulatePressure: element.simulatePressure,
    size: element.strokeWidth * 4.25,
    thinning: 0.6,
    smoothing: 0.5,
    streamline: 0.5,
    easing: (t) => Math.sin((t * Math.PI) / 2), // https://easings.net/#easeOutSine
    last: !!element.lastCommittedPoint, // LastCommittedPoint is added on pointerup
  };

  return getSvgPathFromStroke(getStroke(inputPoints as number[][], options));
}

function med(A: number[], B: number[]) {
  return [(A[0] + B[0]) / 2, (A[1] + B[1]) / 2];
}

// Trim SVG path data so number are each two decimal points. This
// improves SVG exports, and prevents rendering errors on points
// with long decimals.
const TO_FIXED_PRECISION = /(\s?[A-Z]?,?-?[0-9]*\.[0-9]{0,2})(([0-9]|e|-)*)/g;

function getSvgPathFromStroke(points: number[][]): string {
  if (!points.length) {
    return "";
  }

  const max = points.length - 1;

  return points
    .reduce(
      (acc, point, i, arr) => {
        if (i === max) {
          acc.push(point, med(point, arr[0]), "L", arr[0], "Z");
        } else {
          acc.push(point, med(point, arr[i + 1]));
        }
        return acc;
      },
      ["M", points[0], "Q"],
    )
    .join(" ")
    .replace(TO_FIXED_PRECISION, "$1");
}

// CHANGED:ADD 2023/08/29 #955
function strokeBoundTextRect(
  context: CanvasRenderingContext2D,
  element: ExcalidrawTextElement,
  container: ExcalidrawTaskElement,
  renderConfig: StaticCanvasRenderConfig,
  padding: number = BOUND_TEXT_PADDING,
) {
  context.fillStyle = typeof element.textBorderOpacity === "number"
    ? `#FFFFFF${Math.round((element.textBorderOpacity * (255 / 100))).toString(16).padStart(2, '0')}`
    : "#FFFFFF";
  context.strokeStyle = element.textBorderNone
    ? "transparent"
    : renderConfig.criticalPathModeEnabled && container.isCriticalPath
      ? renderConfig.criticalPathColor
      : element.strokeColor;
  context.lineWidth = 1.8;
  const x = -1 * padding;
  const y = -1 * padding;
  const width = element.width + padding * 2;
  const height = element.height + padding * 2;
  const radius = 5;
  context.beginPath();
  context.moveTo(x + radius, y);
  context.arcTo(x + width, y, x + width, y + height, radius);
  context.arcTo(x + width, y + height, x, y + height, radius);
  context.arcTo(x, y + height, x, y, radius);
  context.arcTo(x, y, x + width, y, radius);
  context.fill();
  context.stroke();
};
