export default {
  primary: "#6DACDA",
  gray: "#7b7b7b",
  danger: "#DF4646"
}

export const CriticalPathColor = {
  primary: "#46AADF", //admin role
  secondary: "#DF4646", // read and write
} as const;

export type CriticalPathColor = typeof CriticalPathColor[keyof typeof CriticalPathColor];

export const CriticalPathColorMap: { [key in CriticalPathColor]: string } = {
  "#46AADF": "水色",
  "#DF4646": "赤色",
};
